import React, { useState,useEffect } from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { adminPhotoApproval, adminPhotoApproveList, imgPath } from '../../AllApi/AdminApi/AdminApi';

import { toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import { Row, Table } from 'react-bootstrap';

const PhotoApproval = () => {


   //----------------------------------------------------------------------------
  
    const [record, setRecord] = useState([])  
    const [data, setData] = useState([]);
    const [photoApprovalObj, setphotoApprovalObj] = useState()
    const [showLargePhoto, setShowLargePhoto] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [selectedMember, setSelectedMember] = useState();
    const [showOperationButtons, setShowOperationButtons] = useState(false);
    const [Regid, setRegid] = useState() ;
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
  
  //----------------------------------API code------------------------------------------


 
   

    const fetchMemberData = async () => {
      const tokenValue = sessionStorage.getItem('token')

      const tokenConfig = {
        headers: {
          token: tokenValue,
        },
      }

      try {
        const response = await axios.get(
          adminPhotoApproveList,
          tokenConfig);
        setRecord(response.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    useEffect(() => {
  fetchMemberData()
}, [])
  

    //--------------------------Image click code-----------------------------------
    const handleButtonClick = (member) => {
      setSelectedPhoto(member.photo);
      setSelectedMember(data);
      setShowOperationButtons(true); 
      setShowLargePhoto(true);
      setRegid(member.reg_id)
      const obj=record.find((item) => item.reg_id === member.reg_id);
    setphotoApprovalObj(obj)
    };
  
    const handleCloseLargePhoto = () => {
      setShowOperationButtons(false); 
      setShowLargePhoto(false);
      setSelectedPhoto(null);
      setSelectedMember(null);
    };

    const handleOperationClick = (operation) => {
      let updatedImageStatus 
      if (operation === 'approve') {
        updatedImageStatus = "1";
        
        toast.success('Photo Approved Successfully!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setTimeout(() => {
           fetchMemberData()
        }, 1000);

      } else if (operation === 'reject') { 
        updatedImageStatus = "2";

        toast.error('Photo Rejected !!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          fetchMemberData()
        }, 1000);

      }  
     
      const data={
        reg_id:photoApprovalObj.reg_id,
        image_status:updatedImageStatus 
      }
   
      axios.put(adminPhotoApproval,data)
      .then((res) => {
        // console.log("===>>",res)
      })
      .catch((err) => console.log(err))
      handleCloseLargePhoto();
    };    

//=====================================DATA TABLE============================================
const footerStyle = {
  backgroundColor: '#660505',
  boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
  padding: '8px',
  textAlign: 'center',
  color: '#fff',
  fontSize: '12px',
  bottom: 0,
  width: '100%',
  letterSpacing: '1px',
}

const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Member Id',
    field: 'member_id',
    sort: 'asc',
  },
  {
    label: 'Reg.Id',
    field: 'reg_id',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'name',
    sort: 'asc',
  },
  {
    label: 'Gender',
    field: 'gender',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'emailid',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mobileno',
    sort: 'asc',
  },
  {
    label: 'Edit',
    field: 'action',
  },
];


const customRows = record.map((item, index) => {
  const { gender, en_fname,en_mname,en_lname, mobileno, emailid,member_id ,reg_id} = item;
  const genderLabel = gender === 1 ? 'Male' : 'Female';
  return {
    srNo: index + 1,
    member_id,
    reg_id,
    name: `${en_fname} ${en_mname} ${en_lname}`,
    gender:genderLabel,
    mobileno,
    emailid,
    action: (
      <button className="viewButtons1" type='submit' onClick={() => handleButtonClick(item)}>View Photo</button>
      
    ),
  };
});


  return (
   
     <>
    <div className="row m-0" >
    <div className='p-0'> <AdminMasterHeader/> </div>

    <div className="filter-buttons row">
        <div className='col-12'>
        <h2 style={{ fontWeight: '550',textAlign:"start"}}>Photo Approval</h2>
        </div>
      </div>

      {/* <Row className='row p-0 m-0 mb-5'>
      <div className='col-1'></div>
      <div  className='col-10'>
      <Table striped bordered hover  className='photoApprove shadow' style={{fontSize:"14px"}}>
      <thead>
        <tr>
          <th className='col-1 col-md-1 p-2 text-center'>Sr.No</th>
          <th className='col-3 col-md-3 p-2 text-center'>Name</th>
          <th className='col-1 col-md-1 p-2 text-center'>Gender</th>
          <th className='col-2 col-md-2 p-2 text-center'>Mobile</th>
          <th className='col-4 col-md-4 p-2 text-center'>Email</th>
          <th className='col-1 col-md-1 p-2 text-center'>View Photo</th>
        </tr>
      </thead>
<tbody>
      {record.map((member,index) => (
        <tr className="text-center" key={index}>
        <td>{index + 1}</td>
          <td>{member.en_fname +
                  '  ' +
                  member.en_mname +
                  '  ' +
                  member.en_lname}</td>
          <td>{member.gender === 1 ? 'Male' : 'Female'}</td>
          <td>{member.mobileno}</td>
          <td>{member.emailid}</td>
          <td>
            <button type='submit' onClick={() => handleButtonClick(member)}  className='viewButtons1'>View Photo</button>
          </td>
        </tr>
        ))}
        </tbody>
      </Table>
      </div>
      <div className='col-1'></div>
      
     </Row> */}
    
     <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
    <div className="col-12 col-sm-1"></div>
    <div className="col-12 col-sm-10" style={{overflowX:"auto"}}>
        <MDBDataTable
          className='mb-5 custom-datatable'
          striped
          bordered
          hover
          small
          paging={!showLargePhoto}
          data={{ columns, rows: customRows }}
          style={{ textAlign: "center", fontSize: "14px" ,}}/>
    </div>
    <div className="col-12 col-sm-1"></div>
    </Row>
     
     
      {showLargePhoto && (
        <div className="large-photo-modal">
          <div className="modal-contentPhotoApp col-12 col-md-4">
          <span className="close1 ms-auto fs-1" onClick={handleCloseLargePhoto}>&times;</span>
            <div className='col-12' style={{textAlign:"center",display:"flex",height:"350px"}}>
            <img
              src={`${imgPath}/${photoApprovalObj.image}`}
              alt={`${photoApprovalObj.en_fname}'s Large Photo`}
              className="large-photo"
            />
          </div>
            {showOperationButtons && (
              <div className="operation-buttons">
                <button className='button1' id='btn' onClick={() => handleOperationClick('approve')}>Approve</button>
                <button className='button2' id='btn' onClick={() => handleOperationClick('reject')}>Reject</button>
              </div>
            )}
          </div>
        </div>
      )}

    </div>
    <div style={footerStyle} className='mt-5 fixed-bottom'>
        &copy; 2024-25 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
     
    </>


  )
}

export default PhotoApproval