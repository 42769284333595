import React, { useEffect, useState } from 'react'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'
import { MDBDataTable } from 'mdbreact'; 
import { EducationAdd, EducationDelete, EducationGetdata, EducationUpdate } from '../../AllApi/AdminApi/AdminApi'

const EducationMaster = () => {

    
  const [show, setShow] = useState(false);
  const [educationName, setEducationName] = useState();
  const [data, setData] = useState([]);
  const [isEditing, setisEditing] = useState(false);
  const [Id, setId] = useState();

  const handleClose = () => setShow(false);
  const [showEdit, setShowEdit] = useState(false);

  // =========== delete modal =========
 const [show1, setShow1] = useState(false);
 const handleClose1 = () => setShow1(false);

 const [itemToDelete, setItemToDelete] = useState(null);

 const handleShow1 = (item) => {
   setItemToDelete(item);
   setShow1(true);
 };  

//  =========== Edit ==============
  const handleEditClose = () => {
    setShowEdit(false);
    setId('');
    setEducationName('');
  };

  const handleEditShow = (item) => {
    setId(item.id);
    setEducationName(item.education_name);
    setShowEdit(true);
  };

  const updateEducation = () => {

    if (!educationName) {
      alert("All fields are required");
      return;
   }
   
    const formData = {
        education_name : educationName
    };

    axios
      .put(`${EducationUpdate}/${Id}`, formData)
      .then((res) => {
        if (res.data === "UPDATE") {
          
          toast.success("Data Updated Successfully!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 2000);

          Getdata();
          handleEditClose();
        }
      })
      .catch((err) => {
        console.error("Error while updating Education:", err);
        if (err.response) {
          console.error("Response data:", err.response.data);
        }
  
        toast.error("Failed to update data, please try again!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };

//==================Save data Logic=================

    const savedata = (e) => {
    e.preventDefault();
  
    if (!educationName) {
      alert("All fields are required");
      return;
   }
  
    const obj ={
        education_name : educationName,
    }
  
    axios
      .post(EducationAdd, obj)
      .then((res) => {
        if (res.data.message === "SAVED") {
          toast.success("Data Saved Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          // Reset form fields and reload the data
          Getdata();
          setEducationName('');
          setId('');
          setShow(false);
        }
      })
      .catch((err) => {
        toast.error("Failed to upload, please try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.error(err);
      });
    };

     //==================Getdata Logic=================

     const Getdata = (e) => {
      axios  
        .get(EducationGetdata)
        .then((res) => {
          if (res.data !== null) {
            setData(res.data);
          }
          setisEditing(false);
        })
        .catch((err) => {
          toast.error("Error!!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          console.log(err);
        });
    };
  
        useEffect(() => {
            Getdata();
        }, [])
  
  
    //================delete data=============

    const onDelete = (item) => {
      axios  
        .delete(`${EducationDelete}/${item.id}`)
        .then((res) => {
          if (res.data === "DELETE") {
            toast.success("Data Deleted Succesfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
          handleClose1(false)
          Getdata();
        })
        .catch((err) => {
          toast.error("Failed to upload, try again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          console.log(err);
        });
    };
  

    //==========================css====================
    const footerStyle = {
        backgroundColor: '#660505',
        boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
        padding: '8px',
        textAlign: 'center',
        color: '#fff',
        fontSize: '12px',
        bottom: 0,
        width: '100%',
        letterSpacing: '1px',
      }

      //===================================================================================
        // =================== Render Table Using MDBDataTable ========================
  const tableData = {
    columns: [
      {
        label: 'Sr.No',
        field: 'srNo',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Id',
        field: 'id',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Education Name',
        field: 'educationName',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Edit',
        field: 'edit',
        sort: 'asc',
        width: 50,
      },
      {
        label: 'Delete',
        field: 'delete',
        sort: 'asc',
        width: 50,
      },
    ],
    rows: data.map((item, index) => ({
      srNo: index + 1,
      id: item.id,
      educationName: item.education_name,
      edit: (
        <button
          className='m-1'
          style={{ color: 'orange', border: '0px', backgroundColor: 'transparent' }}
          onClick={() => handleEditShow(item)}
        >
          <i className="fa-solid fa-pen-to-square"></i>
        </button>
      ),
      delete: (
        <button
          className='m-1'
          style={{ color: 'red', border: '0px', backgroundColor: 'transparent' }}
          onClick={() => handleShow1(item)}
        >
          <i className="fa-solid fa-trash"></i>
        </button>
      ),
    })),
  };

  //===================================================================================

  return (
    <>
    <div className="row m-0">
      <div className="p-0">
        {' '}
        <AdminMasterHeader />{' '}
      </div>

        <div className="filter-buttons row">
            <div className='col-12'>
            <h4 style={{ fontWeight: '550',textAlign:"start"}}>Education Master</h4>
            </div>
        </div>

<Row  className='m-0'>

{/* ====================== table =============== */}
<Row className='m-0 px-md-5  py-1 mb-5  w-100' style={{display:"flex",}}>

<Row className='m-0 mb-3' style={{display:"flex",justifyContent:"center",}}>
<Col md={3}></Col>
<Col md={6} style={{boxShadow:"2px 2px 10px grey",padding:"10px"}}>

<Form encType="multipart/form-data">

<Row className='m-0'>
  <Col sm={12} md={12} className='my-3'>
   <Form.Label><h6 ><b>Education Name :</b></h6></Form.Label>
    <Form.Control
      aria-label="Default select example"
      onChange={(e)=>setEducationName(e.target.value)}
      value={educationName}
      maxLength={40}
      required
    >
    </Form.Control>
  </Col>

</Row>

<Row className='m-0'>
        <Col md={3}></Col>
        <Col md={6} style={{display:"flex",justifyContent:"space-evenly"}}>
                    <Button style={{color:"black",border:"1px solid #660505",backgroundColor:"#e9e0e3" ,borderRadius:"5px"}}className='py-2 px-3 shadow' type='submit' onClick={savedata}>
                        Save 
                    </Button>
                
        </Col>
        <Col md={3}></Col>
           
</Row>

</Form>
</Col>
<Col md={3}></Col>

</Row>

<div style={{ overflowY: 'auto'}}>
<div style={{ overflowX: 'auto' }}>
  {/* <Table striped bordered hover size="sm" className='shadow mb-5 mt-2' style={{textAlign:"center"}}>
      <thead >
          <tr> 
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Sr.No</th>
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Id</th>
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Education Name</th>
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Edit</th>
              <th className='text-center' style={{backgroundColor:"#660505",color:"white"}}>Delete</th>
          </tr>
      </thead>
      <tbody>
        {data.map((item,index)=>{
          return(               
          <tr className='border' style={{fontSize:"14px"}}>
              <td style={{ textAlign:"center"}}>{index+1}</td>
              <td style={{ textAlign:"center"}}>{item.id}</td>
              <td style={{ textAlign:"center"}}>{item.education_name}</td>
              <td style={{textAlign:"center"}}>
             <button className=' m-1' style={{color:"orange",border:"0px", backgroundColor:"transparent"}}
               onClick={() => handleEditShow(item)}
             ><i class="fa-solid fa-pen-to-square"></i></button></td>
             <td style={{textAlign:"center"}}>
             <button className=' m-1' style={{color:"red",border:"0px", backgroundColor:"transparent"}}
             
             onClick={() => handleShow1(item)}
              ><i className="fa-solid fa-trash"></i></button></td>
          </tr>
           )
          })} 
      </tbody>
  </Table> */}

  <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
    <div className="col-12 col-sm-1"></div>
    <div className="col-12 col-sm-10 mb-5" style={{ boxShadow: '3px 3px 10px grey', padding: '10px',overflowX:"auto" }}>
  <MDBDataTable
        striped
        bordered
        hover
        data={tableData}
        className='custom-datatable' 
        paging={!showEdit}
        style={{ textAlign: "center", fontSize: "14px" ,}} />
    </div>
    <div className="col-12 col-sm-1"></div>
    </Row>

  </div>
  </div>
</Row>




{/* =================== Edit Modal ======================== */}

<Modal show={showEdit} onHide={handleEditClose}>
   <Modal.Header className='m-0 p-0' style={{display:"flex",justifyContent:"center",color:"white",backgroundColor:"#660505"}} >
   <Modal.Title >
   <p style={{fontSize:"15px"}} className='text-center mt-2'><b>Education Master</b></p>
   </Modal.Title>
 </Modal.Header>

<Modal.Body>
<Form encType="multipart/form-data">
<Row className='m-0'>
  <Col sm={12} md={12} className='mt-1'>
  <Form.Label><h6 ><b>Education Name :</b></h6></Form.Label>
    <Form.Control
      aria-label="Default select example"
      onChange={(e)=>setEducationName(e.target.value)}
      value={educationName}
      required
      maxLength={40}
    >
    </Form.Control>
  </Col>

</Row>
</Form>
</Modal.Body>

 <Modal.Footer className='m-0 p-0'>
   <Button style={{color:"black",backgroundColor:"white",border:"1px solid grey",borderRadius:"5px"}} onClick={handleEditClose}>
     Cancel
   </Button>

       <Button style={{color:"black",backgroundColor:"#F1C40F",border:"none" ,borderRadius:"5px"}} className='py-2 px-3 mx-2  shadow' onClick={() => updateEducation(educationName)}>
         Update
       </Button>
     </Modal.Footer>
   </Modal>


   {/* ============= Delete Modal ========= */}

   <Modal show={show1} onHide={handleClose1}>
     <Modal.Header style={{backgroundColor:"#660505",color:"white"}} >
       <Modal.Title>Delete Confirmation</Modal.Title>
     </Modal.Header>
     <Modal.Body >Are you sure want to delete this item?</Modal.Body>
       <p style={{display:"flex",justifyContent:"end"}}>
       <Button className='me-2' style={{color:"black",backgroundColor:"white",border:"1px solid",borderRadius:"5px"}}  onClick={handleClose1}>
         Cancel
       </Button>
       <Button  style={{border:"none",backgroundColor:"red" ,borderRadius:"5px"}} className='me-2 shadow text-light' onClick={() => onDelete(itemToDelete)}>
         Delete
       </Button></p>
   </Modal>

</Row>


    </div>
    <div style={footerStyle} className='mt-5 fixed-bottom'>
      &copy; 2024-25 Jain Boarding | Managed By : TechnoAarv Solution
    </div>
  </>
  )
}

export default EducationMaster