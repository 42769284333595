
// export const BaseIP = 'http://192.168.29.112:8091/'; //===local

export const BaseIP = 'https://registration.jainboardingsangli.com/'; //====online (old project)
// export const BaseIP = 'https://vadhuvar.jainboardingsangli.com/'; //====online (new project)



export const userRegistration= BaseIP+ "api/member_logins/registerMember" //User registration
export const UserLogin= BaseIP+ "api/member_logins/memberlogin" //User Login

export const EducationMaster= BaseIP+ "api/education/select" //Education Master
export const SubeducationMaster= BaseIP+ "api/subeducation/select" //Education Master
export const subcastMaster= BaseIP+ "api/subcast/select" // SubCast master
export const IncomeMaster= BaseIP+ "api/income/select" // Income master
export const StateMaster= BaseIP+ "api/state/getState" // State master
export const DistrictMaster= BaseIP+ "api/district/getDistrict" // District master
export const TalukaMaster= BaseIP+ "api/taluka/getTaluka" // Taluka master

export const RegsitrationSave=BaseIP+"api/registration/save"// registrationSave
export const RegistrationUpdate=BaseIP+"api/registration/update" // checkbox api
export const RegistrationUpload=BaseIP+"api/registration/upload"//Upload api
export const RegistrationSelect=BaseIP+"api/registration/select"//Personal detail api //============
export const RegistrationUpdateProfile=BaseIP+"api/registration/update/profile"//Update
export const PaymentUpdate=BaseIP+"api/registration/payment"//payment Update

export const Forgetuser=BaseIP+"api/member_logins/forgotPassword"//Forgot Password
export const RegistrationStop=BaseIP+"api/locakDate/getlocakDate"//registration stop


export const PaymentSuccessApi=BaseIP+"api/manual_payment/receiptdata"//Payment Success (view profile==> receipt data show )

export const PaymentAddApi=BaseIP+"api/payment/processPayment/none"//Payment Success Add 



// ==================Admin BarCode=========
export const QrAdminLogin=BaseIP+"api/attendance/adminlogin"
export const EntryDetail=BaseIP+"api/attendance/list"

export const BarCodeRecipt=BaseIP+"api/attendance/barcode"
export const BarCodeScanningPerson=BaseIP+"api/attendance/attendanceDetails"
export const Getattendancedata=BaseIP+"api/attendance/getRegId"
export const selectregdataforsacn=BaseIP+"api/registration/select/scan/"
export const BarcodeScanPage=BaseIP+"api/registration/select/list"



//===========New Payment page======== 
export const UserPaymentAdd = BaseIP+"api/manual_payment/add"