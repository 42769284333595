import React from 'react';
import '../UserCommonComponent/CommonComponent.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../../assets/Mainlogo.jpg';
import { Link } from 'react-router-dom';




const UserMasterFooter = () => {

  return (
    <div className=' m-0'>
   <Container fluid className='footer-container p-md-3 m-0 px-md-3' style={{boxSizing:"border-box"}}>
      <Row className='py-3'>
        <Col sm={12} md={6} className='text-white text-center  py-3'>
        <div >
          <img src={Logo}  alt="" width={100} height={100}/>
        </div> 
        <div className='text-center pt-3' >
            <h6 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h6>
            <h5 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h5>
            <h6 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h6>
        </div>
        </Col>
        {/* <Col sm={12} md={3} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color:"rgb(233, 224, 227)", paddingBottom:"15px"}}><b>Information</b></h4>
            </div>
            <div className='text-start ps-5'>
            <Link to="/PrivacyPolicy"
                  style={{textDecoration:"none", color:"white" }} >
                   <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>   
                    Privacy Policy 
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white",}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            <Link to="/ReturnAndRefundPrivacy"
                  style={{textDecoration:"none", color:"white",}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Return & Refund Policy
            </Link><br/>
            <Link to="/Cancelation"
                  style={{textDecoration:"none", color:"white",}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Cancelation Policy
            </Link><br/>
            </div>
        </Col> */}

        <Col sm={12} md={6} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color: "rgb(233, 224, 227)", paddingBottom:"15px"}}><b>Contact</b></h4>
            </div>
            <div className='text-start ps-5'>
            <a style={{textDecoration: "none",  color: "white" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-address-book"></i><span>Dakshin Bharat Jain Sabha's Sheth R D Dawada Digambar Jain Boarding, Sangli</span>
              </a>
              <br/>
            <a style={{textDecoration: "none",  color: "white" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-location-dot"></i>
              <b>Address</b> : <span>37, Mahavirnagar, Opp Sangli Highschool, Sangli-416416</span>
              </a>
              <br/>
            <a href="mailto:jainboardingsangli@gmail.com" style={{textDecoration: "none", color: "white", color: "white" }}>
              <i style={{ paddingRight: "10px", color:"white"}} className="fa-solid fa-envelope text-light"></i>   
              Email : <span>jainboardingsangli@gmail.com</span>
              </a>
              <br/>
              <a href="tel:02332623206" style={{ textDecoration: "none", color: "white", color: "white" }}>
              <i style={{ paddingRight: "10px",color:"white" }} className="fa-solid fa-phone text-light"></i> 
              Contact: 0233 2623206
            </a><br/>
            </div>
        </Col>
      </Row>

      <Row>
        <Col>
        <div className='text-center' style={{display:"flex", justifyContent:"center", color:"white", paddingTop:"20px", borderTop:"1px solid gray",paddingBottom:"11px"}}>
            &copy; 2024-25 Jain Boarding | Managed By : TechnoAarv Solution
        </div>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default UserMasterFooter