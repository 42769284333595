import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../BarCodeLogin/LoginQr.css"
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import beforelogo from '../assets/registration-before.png';
import afterlogo from '../assets/login-after.png';
import Barcode from '../assets/QRcode.png';
import { BarCodeScanningPerson, PaymentSuccessApi, RegistrationSelect, selectregdataforsacn, } from '../AllApi/UserApi/UserApi';
import { imgPath } from '../AllApi/AdminApi/AdminApi';
import { Input } from '@mui/material';
import { Form } from 'react-bootstrap';
import { toast } from "react-toastify";

const DataPage = () => {
  
  useEffect(() =>{
    window.scrollTo(0, 0);
  },[])

  const location =useLocation()
  const state = location.state?.Reg_id ;

  const [Userdata, setUserdata] = useState()

  const getUserData = (id) => {


    axios.get(`${selectregdataforsacn}${id}`)
      .then((res) => {
        if (res.data.json !== null) {
          setUserdata(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // =============PaymentRecipt==============
          const [Userdatapay, setUserdatapay] = useState([])

         
          const getUserDatapay = (id) => {


          axios.get(`${PaymentSuccessApi}/${id}`)
            .then((res) => {
              
              if (res.data !== null) {
                setUserdatapay(res.data)
              }
            })
            .catch((err) => {
              console.log(err)
            })
          }

          useEffect(() => {
            getUserData(state)
            getUserDatapay(state)
          }, [state])

    const navigate = useNavigate()
  
  const logoutFun = () => {
    // sessionStorage.removeItem("userdata");
    // sessionStorage.clear()
    navigate("/loginBarCode");    
  }



  // =================Recipt Brought by ===
  const [BroughtBy,setBroughtBy] = useState()
  const [MobileNum,setMobileNum] = useState()
  const [Person,setPerson] = useState()

  const handleSubmit = (event) => {

    event.preventDefault();
    const saveObj = {
      reg_id:state,
      Candidate_Name:Userdatapay.fname,
      Name_Receipt_Brought_By:BroughtBy ,
      Mobile_No:MobileNum ,
      No_of_person:Person ,
    }
    axios
      .post(BarCodeScanningPerson, saveObj)
      .then((res) => {
            
            if (res.data !== null) {
              toast.success("Saved Succesfully !", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              navigate('/scanPage')
            }
        })
        .catch((err) => console.log(err));
     }

  //=====================Entry Save==============

    

   //--------------------------CSS---------------------------------
   const style1 = { lineHeight: "15px", fontSize: "14px" }
   const style3 = { lineHeight: "15px", fontSize: "14px" }
   const style = { fontSize: "14px", width: "100%", padding: "5px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
   const style2 = { border: "1px solid #f796ec", padding: "8px 5px", alignItems: "center", height: "88px", margin: "0px 1px 0px 1px", textAlign: "start" }
   const style4 = { display:"flex",lineHeight: "15px", fontSize: "14px",justifyContent:"center" }
   const style5 = { display:"flex",lineHeight: "15px", fontSize: "14px",justifyContent:"center",flexDirection:"column" }

  return (
    <Container fluid className='p-0 m-0 ' style={{boxSizing:"border-box"}}>
      <Row className='header m-0 p-0'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={160} height={120}/>
            </div> 
       </Col> 
       
       <Col md={6} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={160} height={130}/>
            </div> 
       </Col>

        {/* <Col md={1} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <Nav.Link  onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>       */}
      </Row>  

      <Row className='mb-3 m-0'>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "7px" }}>
            <img src={beforelogo}  />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 className='mt-1 mb-0 pb-1 text-center' style={{ color: "rgb(134, 28, 18)" }}><b>Function Entry Attendance</b></h2>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={afterlogo} />
        </div>
     </Row>

     {/* ============================Name  details...=============== */}
   
     {
            Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

              return (
                <>
                <div className='row p-0 m-0' >
                  <div className='col-12 col-md-1'></div>
                  <div className='col-12 col-md-10 p-0' style={{ backgroundColor: "white", overflow: "scroll"}} >
                    <div className='p-2' style={{ backgroundColor: "#f796ec", display: "flex", alignItems: "center", width: "1100px" }}>
                      <div className='col-3'></div>
                      <div className='col-9 me-3' style={{ alignItems: "center", display: "flex" }}>
                        <div className='col-9' style={{ margin: "0px 0px 0px -70px", alignItems: "end", }}><span style={{ fontWeight: "bold", fontSize: "20px", }}>{value.mr_fname}/{' '}{value.mr_fatherfname}{' '}{value.mr_fathermname}{' '} {value.mr_fatherlname} </span></div>

                        <div className='col-3 me-5' style={{ textAlign: "center", padding: "6px", border: "2px solid white", borderRadius: "50px", backgroundColor: "white", margin: "0px 0px 0px 50px" }}><label style={{ fontWeight: "bold", }}>{value.gender === 1 ? 'APP2024/' : 'APP2024/'}{value.member_id}</label></div>
                      </div>
                    </div>

                    <div className=' d-flex p-0' style={{ border: "2px solid #c42fb2", borderTopColor: "#f796ec", width: "1100px", backgroundColor: "white" }}>
                      <div className='col-2 p-2' style={{ justifyContent: "center", display: "flex" }}>
                        <img src={`${imgPath}/${value.image}`} alt={`${value.mr_fname}'s Photo`} style={{ width: "100%", height: "200px", border: "1px solid grey" }} />
                      </div>

                      {/* <div className='col-10 px-4 py-2'>
                        <div className='row p-0'>
                          <div className='col-8 p-0 pt-2' style={{ lineHeight: "10px" }}>
                            <p style={{ fontSize: "15px", lineHeight: "18px" }}><b>पत्ता : </b>{' '} {value.mr_address}{' '}&nbsp;<b>गाव/ शहर : </b>{value.mr_village}{' '}&nbsp;<b>ता : </b>{value.talukamrname}{' '}&nbsp;<b>जि : </b>{value.districtmrname} {' '}&nbsp;<b>रा : </b>{value.statemrname}</p>
                            <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>ता : </b>{' '}{value.mobileno}/{value.mobileno2}</p>
                            <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>व्यवसाय : </b>{' '}{value.occupation},{value.occupation_detail}</p>
                          </div>
                          
                          <div className='col-4 p-0' style={{ alignItems: "end", display: "flex", flexDirection: "column" }}>
                            
                                <div className='d-flex mb-1' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                  <div style={{ padding: "10px 15px", color: "white", width: "50px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>भाऊ:</b></div>

                                  <div style={{ width: "180px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                           
                                   
                                      <span style={{ fontSize: "14px" }}><b>विवाहित-</b>{value.b_married > 0 ? value.b_married: '-' }</span>
                                    
                                    
                                      <span style={{ fontSize: "14px" }} className='mx-2'><b> अविवाहित-</b>{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                                  

                            
                                  </div>
                                  
                                </div>
                               

                                <div className='d-flex mt-1' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                  <div style={{ padding: "10px 15px", color: "white", width: "50px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>बहीण:</b></div>

                                  <div style={{ width: "180px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                           
                                   
                           <span style={{ fontSize: "14px" }}><b>विवाहित-</b>{value.s_married > 0 ? value.s_married: '-' }</span>
                         
                         
                           <span style={{ fontSize: "14px" }}  className='mx-2'><b> अविवाहित-</b>{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span>
                       

                 
                       </div>
                                </div>
                              
                          </div>
                        </div>


                        <div className="row p-0 d-flex mt-2">
                          <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}> <b>जन्मतारीख व वेळ</b></div>
                            <div style={style2} > <p style={style4}>{value.dob}</p> <p style={style4}>{value.birth_time}</p></div>
                          </div>

                          <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}><b>उंची व वर्ण</b></div>
                            <div style={style2}> <p style={style4}>{value.height}</p> <p style={style4}>{value.complextion}</p></div>
                          </div>

                          <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}><b>शिक्षण</b></div>
                            <div style={style2}> <h6 style={style5} className='text-center'>{value.subeducation_name},&nbsp;<p className='text-center pt-1' style={style5}>{value.other_education}</p></h6>
                            </div>
                          </div>

                          <div className='col-3 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}><b>वार्षिक उत्पन्न/मालमत्ता</b></div>
                            <div style={style2}> <h6 style={style4}>{value.amount}</h6>
                              { value.farm_g > 0 || value.farm_ac > 0 ? (
                                <>
                                  <h6 style={style4}><b>शेती -&nbsp;</b>
                                  {value.farm_ac > 0 ? (
                                 <span> {value.farm_ac}&nbsp; एकर{' '}</span>
                                  ) : null}
                                  {value.farm_g > 0 ? (
                                    <span style={style3}>{' '} &nbsp; {value.farm_g} &nbsp; गुंठे{' '}</span>
                                    ) : null
                                  }
                                  </h6>
                                </>
                              ) : null}

                              <span className='text-center' style={style4}>{value.other_property}</span></div>
                          </div>

                          <div className='col-1 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={{ fontSize: "14px", width: "100%", padding: "5px 0px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }}><b>पोटशाखा</b></div>
                            <div style={style2}> <p style={style4}>{value.subcastename}</p></div>
                          </div>

                          <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}><b>अपेक्षा</b></div>
                            <div style={style2}> <p style={{lineHeight: "24px", fontSize: "14px",display:"flex",flexDirection:"column",textAlign:"center",margin:"0px 20px"}}>{' '}{value.exp_housewife === 1 ? 'गृहिणी ' : null}{' '}{value.exp_compatible === 1 ? 'अनुरूप ' : null}{' '}{value.exp_farmer === 1 ? 'शेती ' : null}{' '}{value.exp_serviceman === 1 ? 'नोकरी ' : null}{' '}{value.exp_businessman === 1 ? 'व्यवसाय ' : null}</p></div>
                          </div>

                        </div>
                      </div> */}
                      <div className='col-10 px-4 py-2'>
                        <div className='row p-0'>
                          <div className='col-8 p-0 pt-2' style={{ lineHeight: "10px" }}>
                            <p style={{ fontSize: "15px", lineHeight: "18px" }}><b>Add : </b>{' '} {value.en_address}{' '}&nbsp;<b>Village/City : </b>{value.en_village}{' '}&nbsp;<b>Tal : </b>{value.talukaenname}{' '}&nbsp;<b>Dist : </b>{value.districtenname} {' '}&nbsp;<b>State : </b>{value.stateenname}</p>
                            <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Mob : </b>{' '}{value.mobileno}/{value.mobileno2}</p>
                            <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Occupation : </b>{' '}{value.occupation},&nbsp;{value.occupation_detail}</p>
                          </div>

                          <div className='col-4 p-0' style={{ alignItems: "end", display: "flex", flexDirection: "column" }}>
                            
                                <div className='d-flex mb-1' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                  <div style={{ padding: "10px 5px", color: "white", width: "60px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Brother</b></div>

                                  <div style={{ width: "180px", display: "flex", padding: "10px 5px", border: "1px solid #f796ec"}} >                           
                                   
                                      <span style={{ fontSize: "13px" }}><b>Married-</b>{value.b_married > 0 ? value.b_married: '-' }</span>
                                          
                                      <span style={{ fontSize: "13px" }} className='ms-2'><b> Unmarried-</b>{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                                                              
                                  </div>
                                  
                                </div>
                               

                                <div className='d-flex mt-1' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                  <div style={{ padding: "10px 5px", color: "white", width: "60px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Sister</b></div>

                                <div style={{ width: "180px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >                           
                                    
                                    <span style={{ fontSize: "13px" }}><b>Married-</b>{value.s_married > 0 ? value.s_married: '-' }</span>                                    
                                        
                                    <span style={{ fontSize: "13px" }}  className='mx-2'><b> Unmarried-</b>{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span>                               
      
                                </div>

                                </div>
                              
                          </div>
                        </div>


                        <div className="row p-0 d-flex mt-2">
                          <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}><b>DOB & Time</b></div>
                            <div style={style2}> <p style={style4}>{value.dob}</p> <p style={style4}>{value.birth_time}</p></div>
                          </div>

                          <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}><b>Hgt & Char.</b></div>
                            <div style={style2}> <p style={style4}>{value.height}</p> <p style={style4}>{value.complextion}</p></div>
                          </div>

                          <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}><b>Education</b></div>
                            <div style={style2}> <h6 style={style5} className='text-center'>{value.subeducation_name},&nbsp;<p className='text-center pt-1' style={style5}>{value.other_education}</p></h6>
                            </div>
                          </div>

                          <div className='col-3 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}><b>Annual Inc/Assets</b></div>
                            <div style={style2}> <h6 style={style4}>{value.amount}</h6>
                              { value.farm_g > 0 || value.farm_ac > 0 ? (
                                <>
                                  <h6 style={style4}><b>Farm -&nbsp;</b>
                                  {value.farm_ac > 0 ? (
                                 <span> {value.farm_ac}&nbsp; acre{' '}</span>
                                  ) : null}
                                  {value.farm_g > 0 ? (
                                    <span style={style3}>{' '}&nbsp; {value.farm_g} &nbsp; gunthe{' '}</span>
                                    ) : null
                                  }
                                  </h6>
                                </>
                              ) : null}

                              <span className='text-center' style={style4}>{value.other_property}</span></div>
                          </div>


                          <div className='col-1 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={{ fontSize: "13px", width: "100%", padding: "5px 0px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }}><b>SubCaste </b></div>
                            <div style={style2}> <p style={style4}>{value.subcastmr_name}</p></div>
                          </div>

                          <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                            <div style={style}><b>Expectations</b></div>
                            <div style={style2}> <p style={{lineHeight: "20px", fontSize: "14px",display:"flex",flexDirection:"column",textAlign:"center",margin:"0px 30px"}}>{' '}{value.exp_housewife === 1 ? 'Housewife ' : null}{value.exp_compatible === 1 ? 'Compatible ' : null}{value.exp_farmer === 1 ? 'Farmer ' : null}{value.exp_serviceman === 1 ? 'Service ' : null}{value.exp_businessman === 1 ? 'Business ' : null}</p></div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                  <div className='col-12 col-md-1'></div>
                  </div>
                </>
              )
            })}

            {/* //------------------payment-------------// */}
        <Row className='p-0 m-0 py-4 '>
          <Col style={{textAlign:"center"}}>
            <h6><b style={{ color:"red" }}>Please carry the print of this Confirmation Receipt at the time of Melava, Without Receipt entry will not be allowed to Melava.</b></h6>

              <h6><b style={{fontFamily:"cursive", color:"red" }}>कृपया मेळाव्याच्या वेळी या पावतीची प्रिंट सोबत ठेवा, पावतीशिवाय मेळाव्याला प्रवेश दिला जाणार नाही.</b></h6>
          </Col>
        </Row>

        <Row className='m-0'>
            <Col md={3}></Col>

            <Col sm={12} md={6}>
              <Row>
                <Col>
                  <b>Application Id :</b>
                </Col>
                <Col>
                  <p>Reg. No. APP2024/{Userdatapay.reg_id}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Payment Date & Time :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.paymentdate}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Payment Receipt Date :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.receipt_date}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Customer Name :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.fname}{' '}{Userdatapay.lname}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Amount :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.amount}</p>
                </Col>
              </Row>

              {/* <Row>
                <Col>
                  <b>Pay-you MoneyId :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.payumoneyid}</p>
                </Col>
              </Row> */}

              <Row>
                <Col>
                  <b>Email Id :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.emailid}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Transaction Id :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.tnxid}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Mode of payment :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.mode}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Mobile No :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.mobileno}</p>
                </Col>
              </Row>

            </Col>

            <Col md={3}></Col>
        </Row>


        <Row className='m-0 pt-4' >
         <Form onSubmit={handleSubmit}>
            <Row className='px-5'>
              <Col md={1}></Col>
              <Col md={10} >
              <Row style={{display:"flex",justifyContent:"space-between"}}>
                  <Col md={5} className='pt-2'>
                      <Form.Control className='py-2 px-3 rounded shadow' md={5} placeholder='Name-Receipt Brought By'
                       value={BroughtBy}
                       onChange={(e) => setBroughtBy(e.target.value)}
                       required></Form.Control>
                  </Col>
                  <Col md={5} className='pt-2'>
                       <Form.Control
                         className='py-2 px-3 rounded shadow' md={5}
                         placeholder='Mobile No.' 
                         value={MobileNum}
                         onChange={(e) => setMobileNum(e.target.value)}
                         required
                         pattern="[0-9]{10}"
                         maxlength="10"
                         title='Please enter 10 digit num.'
                         type="tel"
                         id="mobileNumber"
                         ></Form.Control>
                  </Col>
                  <Col md={2} className='pt-2'>
                     <Form.Select className='py-2 px-3 rounded shadow' md={2} placeholder='No. Of Person' required
                     value={Person}
                     onChange={(e) => setPerson(e.target.value)}
                     >
                        <option value="">No. of Person</option>
                        <option >1</option>
                        <option >2</option>
                        <option >3</option>
                        <option >4</option>
                     </Form.Select>
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row className='m-0 pb-md-3' >
              <Col className='py-4' style={{ textAlign: "center", paddingTop: "10px", }}>
                    <button type="submit" className=' rounded' style={{ padding: "7px 19px", backgroundColor: "rgb(134, 28, 18)", border: "0px", color: "white", fontSize: "20px" }} > Entry Save
                    </button>
              </Col>
            </Row>
           </Form>
         </Row>

         {/* -----------------footer login --------------------------- */}
      <div className='m-0'>
        <Container fluid className='footer1-container p-md-2 m-0 px-md-5' style={{boxSizing:"border-box"}}>
          <Row>
            <Col>
            <div className='text-center' style={{display:"flex", justifyContent:"center", color:"white", padding:"2px 0px", color: "rgb(134, 28, 18)"}}>
                &copy; <b>2024-25 Jain Boarding | Managed By : TechnoAarv Solution</b>
            </div>
            </Col>
          </Row>
        </Container>
      </div>

    </Container>
  )
}

export default DataPage
