import React, { useEffect, useState } from 'react'
import './LoginQr.css'
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import beforelogo from '../assets/registration-before.png';
import afterlogo from '../assets/login-after.png';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { Getattendancedata } from '../AllApi/UserApi/UserApi';

const LoginQr = () => {

  useEffect(() =>{
    window.scrollTo(0, 0);
  },[])
  
  const [data, setdata] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state?.Reg_id;

  const logoutFun = () => {
    // sessionStorage.removeItem("userdata");
    // sessionStorage.clear()
    navigate("/scanPage");
  }

  const getdata = (id) => {
    axios.get(`${Getattendancedata}${id}`)
      .then((res) => {
        if (res.data.message === 'ALREDYEXIST') {
          setdata(res.data.data)
        }
      })
      .catch((err) => {
        // alert("Failed to record Education Master")
        console.log(err)
      })
  }
  useEffect(() => {
    getdata(state)
  }, [state])

  return (
    <Container fluid className='p-0 m-0' style={{ boxSizing: "border-box" }}>
      <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <img src={Logo2} style={{ mixBlendMode: "darken" }} alt="" width={100} height={100} />
          </div>
        </Col>

        <Col md={5} sm={12} style={{ display: "flex", justifyContent: "center" }}>
          <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
          </div>
        </Col>

        <Col md={3} sm={12} style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <img src={Logo} style={{ mixBlendMode: "darken" }} alt="" width={100} height={100} />
          </div>
        </Col>

        <Col md={1} sm={12} style={{ display: "flex", justifyContent: "center" }}>
          <Nav.Link onClick={logoutFun}><button className='py-2 px-4 rounded' style={{backgroundColor:"red",color:"white" ,border:"0px"}}><b>Back</b></button></Nav.Link>
        </Col>
      </Row>

      <Row className='mb-3 m-0'>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "7px" }}>
          <img src={beforelogo} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2 className='mt-1 mb-0 pb-1 text-center' style={{ color: "rgb(134, 28, 18)" }}><b>Function Entry Attendance</b></h2>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={afterlogo} />
        </div>
      </Row>

      <Row className='m-0 pt-md-5 p-md-4 pb-md-0'>
        <Col className='EntryDone text-danger' style={{ display: "flex", justifyContent: "center" }}>
          <h3 ><b >Already Entry Done.</b><h1 style={{ color: "rgb(0, 247, 0)", fontSize: "50px" }}><b><i class="fa-solid fa-check"></i></b></h1></h3>
        </Col>
      </Row>
      <Row className='m-0 p-md-5 pt-md-0' style={{overflowX:"auto"}}>
        <Table striped bordered hover size="sm" style={{overflowX:"auto"}}>
          <thead>
            <tr className='text-center'>
              <th>Reg.Id</th>
              <th>Candidate Name</th>
              <th>Name-Receipt Brought By</th>
              <th>Mobile No.</th>
              <th>Date & Time</th>
              <th>No. Of Person</th>
            </tr>
          </thead>
          <tbody>
          {/* {
            data && Array.isArray(data) && data.map((value , index) => {

              return (
                <> */}
            <tr className='text-center'>
              <td>{data.reg_id}</td>
              <td>{data.Candidate_Name}</td>
              <td>{data.Name_Receipt_Brought_By}</td>
              <td>{data.Mobile_No}</td>
              <td>{data.dateTime}</td>
              <td>{data.No_of_person}</td>
            </tr>
            {/* </>
              );
            })} */}
          </tbody>
        </Table>
      </Row>
      {/* Candidate_Name
: 
"pranali satale"
Mobile_No
: 
"9876543210"
Name_Receipt_Brought_By
: 
"parents"
No_of_person
: 
1
createdAt
: 
"2023-09-21T10:33:15.000Z"
dateTime
: 
"2023-09-21T10:33:15.000Z"
id
: 
1
reg_id
: 
8
updatedAt
: 
"2023-09-21T10:33:15.000Z" */}
      {/* -----------------footer login --------------------------- */}
      <div className='m-0'>
        <Container fluid className='footer1-container p-md-2 m-0 px-md-5' style={{ boxSizing: "border-box" }}>
          <Row>
            <Col>
              <div className='text-center' style={{ display: "flex", justifyContent: "center", color: "white", padding: "15px  0px", color: "rgb(134, 28, 18)" }}>
                &copy; <b>2024-25 Jain Boarding | Managed By : TechnoAarv Solution</b>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </Container>





  )
}

export default LoginQr
