import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UserMasterFooter from '../UserCommonComponent/UserMasterFooter';
import Logo from '../../assets/Mainlogo.jpg'
import Logo2 from '../../assets/logo2.png'
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';


function ReturnAndRefundPrivacy() {

  const navigate=useNavigate()
  useEffect(() =>{
    window.scrollTo(0, 0);
  },[])

  return (
 <div>
    <div>
    <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={6} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 

      </Row> 

      <Navbar expand="lg" style={{backgroundColor: "#660505"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(233, 224, 227)"}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/" className=' mx-2 text-white'>Home</Nav.Link>
            <Nav.Link href="/#/ReturnAndRefundPrivacy" className=' mx-2 text-white'>Return & Refund Policy</Nav.Link>
            <Nav.Link href="/#/Cancelation" className=' mx-2 text-white'>Cancelation Policy</Nav.Link>
            <Nav.Link href="/#/terms&Condition" className=' mx-2 text-white'>Terms & Condition</Nav.Link>
            <Nav.Link href="/#/PrivacyPolicy" className=' mx-2 text-white'>Privacy Policy</Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
        </Container>
    </Navbar>
    </div>

    <div className='p-md-4 p-3 mx-md-5'>
      <Nav.Link href="/ReturnAndRefundPrivacy" style={{color: "rgb(134, 28, 18)"}}><h3><b>Return And Refund Policy</b></h3></Nav.Link>
   
          <p>All refund requests must be submitted within 3 days after the registration date.</p>
          <p>Refunds will be processed only for valid requests, which include errors during registration or payment processing.</p>
          <p>No refund will be provided after the 3-days window has passed.</p>
          <p>Refunds will be issued via cheque only, regardless of the original payment method.</p>
          <p>Process the refund after the request has been approved.</p>
          <p>The cheque will be mailed to the address provided during registration. Ensure your mailing address is correct.</p>
          <p>In case of a cheque being lost or not received, the participant must notify us within 30 days to issue a replacement.</p>
          <p>Refunds will be for the full registration amount, minus any applicable service charges.</p>
          <p>Refund requests must include a valid reason and supporting documents if applicable.</p>
          <p>The organization reserves the right to deny any refund request that does not meet the specified criteria.</p>
        
    
          <h5>Questions or Suggestions</h5>
          <p>If you have questions or suggestions send an email to us:</p>
          <p><a >jainboardingsangli@gmail.com</a></p>
      </div>


      <div>
      <Container fluid className='footer1-container p-md-2 m-0 px-md-5' style={{boxSizing:"border-box"}}>
      <Row className='py-1' >
        <Col sm={12} md={5} className='text-white text-center  py-3'>
        <div >
          <img src={Logo}  alt="" width={100} height={100}/>
        </div> 
        <div className='text-center' style={{color: "rgb(134, 28, 18)"}}>
            <h6 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h6>
            <h5 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h5>
            <h6 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h6>
        </div>
        </Col>
        <Col sm={12} md={3} className='text-center py-3 ' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color:"rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Information</b></h4>
            </div>
            <div className='text-start ps-5'>
            <Link to="/PrivacyPolicy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)" }} >
                   <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>   
                    Privacy Policy 
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            <Link to="/ReturnAndRefundPrivacy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Return & Refund Policy
            </Link><br/>
            <Link to="/Cancelation"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Cancelation Policy
            </Link><br/>
            {/* <Link to="/Registration1"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Registration
            </Link><br/> */}
            <Link to="/loginBarCode"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login(Entry Pass)
            </Link><br/>
            <Link to="/admin"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login
            </Link><br/>
            </div>
        </Col>
        <Col sm={12} md={4} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color: "rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Contact</b></h4>
            </div>
            <div className='text-start ps-5'>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-address-book"></i><span>Dakshin Bharat Jain Sabha's Sheth R D Dawada Digambar Jain Boarding, Sangli</span>
              </a>
              <br/>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-location-dot"></i>
              <b>Address</b> : <span>37, Mahavirnagar, Opp Sangli Highschool, Sangli-416416</span>
              </a>
              <br/>
            <a href="mailto:jainboardingsangli@gmail.com" style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-envelope"></i>   
              <b>Email</b> : <span>jainboardingsangli@gmail.com</span>
              </a>
              <br/>
              <a href="tel:02332623206" style={{ textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-phone"></i> 
             <b>Contact</b> : 0233 2623206
            </a><br/>
            </div>
        </Col>
      </Row>
      <Row>
        <Col>
        <div style={{display:"flex", justifyContent:"center", color:"white", padding:"20px 0px 20px 0px", borderTop:"1px solid rgb(134, 28, 18) ",color: "rgb(134, 28, 18)"}}>
            &copy; 2024-25 Jain Boarding | Managed By : TechnoAarv Solution
        </div>
        </Col>
      </Row>
    </Container>
      </div>
    </div>
   )
} 

export default ReturnAndRefundPrivacy


