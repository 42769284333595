
import React, { useState, useEffect } from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Row, Table, Col, Modal } from 'react-bootstrap'
import Logo from '../../assets/logo2.png'
import Logo1 from '../../assets/logo2.png'
import loaderImage from '../../assets/loader.gif';
import { MDBDataTable } from 'mdbreact'
import { adminPaymentApproval, adminPaymentApprovePending, ApprovalViewDetails, imgPath, paymentImgPath, RegistrationSelect } from '../../AllApi/AdminApi/AdminApi'


const PaymentApproval = ({}) => {
  // ----------------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [record, setRecord] = useState([])
  const [record1, setRecord1] = useState([])
  const [disabilityobj, setdisabilityobj] = useState()
  const [showLargePhoto, setShowLargePhoto] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [selectedMember, setSelectedMember] = useState(null)
  const [showOperationButtons, setShowOperationButtons] = useState(false)
  const [Regid, setRegid] = useState()

  const [isViewingProfile, setIsViewingProfile] = useState(false)

  const [member_id, setMember_id] = useState()

  const [paymentCheckbox, setPaymentCheckbox] = useState(false)
  const [widowCheckbox, setWidowCheckbox] = useState(false);
  const [disabilityCheckbox, setDisabilityCheckbox] = useState(false);
  const [reason, setReason] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  //===================================================================

  const handleCheckboxChange = (checkboxType) => {
    if (checkboxType === 'payment') {
      setPaymentCheckbox(!paymentCheckbox);
    } else if (checkboxType === 'widow') {
      setWidowCheckbox(!widowCheckbox);
    } else if (checkboxType === 'disability') {
      setDisabilityCheckbox(!disabilityCheckbox);
    }
  }

  const handleApprove = async (type) => {
    setLoading1(true)
    let status;
    if (type === 'marital_status' || type === 'disability' || type === 'payment') {
      status = 2;
      await updateStatus(type, status, '');
    }
  };

  const handleReject = (type) => {
  
    setModalType(type);
    setShowModal(true)
  };

  //----------------------------------API code------------------------------------------

  useEffect(() => {
    fetchMemberData()
  }, [])
 
    const fetchMemberData = async () => {

      const tokenValue = sessionStorage.getItem('token')

      const tokenConfig = {
        headers: {
          token: tokenValue,
        },
      }
  
      try {
        const response = await axios.get(
          adminPaymentApprovePending,
          tokenConfig,
        )
        setRecord(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

  

  //--------------------------Image click code-----------------------------------

  const fetchMemberData1 = async (member) => {
    
    try {
      const response = await axios.get(`${ApprovalViewDetails}/${member.member_id}`)
      const data = response.data[0]
      
      setRecord1(data)
      
      setSelectedMember(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleImageClick = (member) => {
    setSelectedPhoto(member.photo)
    setSelectedMember(record1)
    setShowLargePhoto(true)
    setMember_id(member.member_id)
    setRegid(member.reg_id)
    setIsViewingProfile(true)
    fetchMemberData1(member)

  } 

  const handleCloseLargePhoto = () => {
    setShowOperationButtons(false)
    setShowLargePhoto(false)
    setSelectedPhoto(null)
    setSelectedMember(null)
  }

  // const handleOperationClick = (operation) => {
  //   let updatedPaymentStatus
  //   if (operation === 'approve') {
  //     updatedPaymentStatus = 2

  //   } else if (operation === 'reject') {
  //     updatedPaymentStatus = 3
  //     setShowRejectionModal(true);
  //   }


  //   const data = {
  //     member_id: record1.member_id,
  //     reg_id: record1.reg_id,
  //     pay_amount:modalData.pay_amount,
  //     status: updatedPaymentStatus,
  //     reason: updatedPaymentStatus === 3 ? rejectionReason : null,
      
  //   }

  //   axios
  //     .put(adminPaymentApproval, data)
  //     .then((res) => {
  //       if(res.data.status==="APPROVE"){

  //           toast.success('Payment Approved Successfully!', {
  //           position: 'top-right',
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //         })

  //         fetchMemberData()
  //       }
  //       else if(res.data.status==="REJECT"){

  //           toast.error('Payment Rejected !!', {
  //             position: 'top-right',
  //             autoClose: 2000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //           })

  //         fetchMemberData()
  //       }
        
  //     })
  //     .catch((err) => console.log(err))
  //   handleCloseLargePhoto()
  // }
   // Reset state when selectedMember changes


   useEffect(() => {
    setPaymentCheckbox(false);
    setWidowCheckbox(false);
    setDisabilityCheckbox(false);
    setReason('');
  }, [selectedMember]);

  const handleModalSubmit = async () => {
    setLoading(true);
    let status;
    if (modalType === 'payment') {
      status = 5;
    } else if (modalType === 'disability') {
      status = 4;
    }else if (modalType === 'marital_status') {
      status = 6;
    }

    await updateStatus(modalType, status, reason);
    setShowModal(false);
  };

  const updateStatus = async (type, status, reason) => {
    const payload = {
      member_id,
      reg_id: Regid,
      status,
      reject_reason:reason,
    };
    
    try {
      const response = await axios.put(adminPaymentApproval, payload);

      
          if (response.data == 'APPROVAL') {
            

            setTimeout(() => {
              toast.success('Approved Successfully!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }, 100);

                    resetState();
                    fetchMemberData();                    
                      
          }
          else if (response.data == 'REJECT') {

                      setTimeout(() => {
                        toast.error('Rejected Successfully!', {
                          position: 'top-right',
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                        });
                      }, 100);

                      resetState();
                      fetchMemberData();                    
                      
          }
    } catch (error) {
      console.error('Error updating status:', error);
    }finally {
      setLoading(false); // Stop loading
      setLoading1(false); // Stop loading1
      handleCloseLargePhoto();
    }
  };

  const resetState = () => {
    setReason('');
    setPaymentCheckbox(false);
    setWidowCheckbox(false);
    setDisabilityCheckbox(false);
  };

  //CSS for Information-------------------------------------------------------------------
  const info = {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5px',
    backgroundColor: '#660505',
    color: 'white',
    fontWeight: '550px',
  }
  const footerStyle = {
    backgroundColor: '#660505',
    boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
    padding: '8px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '12px',
    bottom: 0,
    width: '100%',
    letterSpacing: '1px',
  }

  //=====================================DATA TABLE============================================
  
  const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Member Id',
      field: 'member_id',
      sort: 'asc',
    },
    {
      label: 'Reg.Id',
      field: 'reg_id',
      sort: 'asc',
    },
    {
      label: 'Name',
      field: 'name',
      sort: 'asc',
    },
    {
      label: 'Gender',
      field: 'genderLabel',
      sort: 'asc',
    },
    {
      label: 'Email Id',
      field: 'email_id',
      sort: 'asc',
    },
    {
      label: 'Mobile No',
      field: 'mobileno',
      sort: 'asc',
    },
    {
      label: 'View Profile',
      field: 'action',
    },
  ]

  const customRows = record.map((item, index) => {
    const {gender,email_id,mobileno,name,member_id,reg_id} = item

    const genderLabel = gender === 1 ? 'Male' : gender === 2 ? 'Female' : 'NA'

    return {
      srNo: index + 1,
      member_id,
      reg_id,
      name,
      genderLabel,
      mobileno,
      email_id,
      action: (
        <button
          className="viewButtons1"
          type="submit"
          onClick={() => handleImageClick(item)}
        >
          View Profile
        </button>
      ),
    }
  })

  // Function to check if the buttons should be displayed
  const shouldDisplayButtons = selectedMember && (

    (selectedMember.disability == 1 && selectedMember.marital_status == "Unmarried" && (selectedMember.gender == 1 || selectedMember.gender == 2)) ||
    (selectedMember.disability == 1 && selectedMember.marital_status == "Divorcee" && (selectedMember.gender == 1 || selectedMember.gender == 2)) ||
    (selectedMember.disability == 1 && selectedMember.marital_status == "Widow" && selectedMember.gender !== 2)
  );
  
//========button style =============
  const approveButtonStyles = (disabled) => ({
    backgroundColor: disabled ? '#d3d3d3' : 'green', // Grey when disabled, original color when enabled
    color: disabled ? '#888' : 'white',
    cursor: disabled ? 'not-allowed' : 'pointer',
    padding: "8px",
    margin: "10px 20px",
    fontWeight: "bold",
    border: "none",
    borderRadius: "10px",
  });

  const rejectButtonStyles = (disabled) => ({
    backgroundColor: disabled ? '#d3d3d3' : 'red', // Grey when disabled, original color when enabled
    color: disabled ? '#888' : 'white',
    cursor: disabled ? 'not-allowed' : 'pointer',
    padding: "8px",
    margin: "10px 20px",
    fontWeight: "bold",
    border: "none",
    borderRadius: "10px",
  });


  return (
    <>
      <div className="row m-0">
        <div className="p-0">
          {' '}
          <AdminMasterHeader />{' '}
        </div>
        <div className="filter-buttons row">
          <div
            className="col-12 pt-1"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <h4 style={{ fontWeight: '550', textAlign: 'start' }}>
              Payment Approval
            </h4>
          </div>
        </div>

        <Row
          className="row p-0 m-0"
          style={{
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
          }}
        >
          <div className="col-12 col-sm-1"></div>
          <div className="col-12 col-sm-10" style={{overflowX:"auto"}}>
            <style>{` .custom-pagination .pagination {display: none;}`}</style>
            <MDBDataTable
              className="mb-5 custom-datatable"
              striped
              bordered
              hover
              small
              data={{ columns, rows: customRows }}
              paging={!showLargePhoto}
              style={{ textAlign: 'center', fontSize: '14px' }}
            />
          </div>
          <div className="col-12 col-sm-1"></div>
        </Row>

        {showLargePhoto && (
          <div className="large-photo-modal">
         

            <div className="modal-content">
              <Row
                className="header m-0"
                style={{ backgroundColor: '#fff', boxShadow: '2px 2px 5px' }}
              >
                <Col
                  md={2}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={Logo}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>

                <Col
                  md={7}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div className="text-center" style={{ color: '#660505' }}>
                    <h5 className="fw-bolder">दक्षिण भारत जैन सभेचे</h5>
                    <h3 className="fw-bolder">
                    शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली{' '}
                    </h3>
                    <h5 className="fw-bolder">जैन वधू - वर नोंदणी 2024</h5>
                  </div>
                </Col>

                <Col
                  md={2}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={Logo1}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>

                <Col
                  md={1}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'end',color:"black",cursor:"pointer" }}
                >
                  <div className="text-center">
                    <span
                      title="Close"
                      className="ms-auto fs-1 me-3"
                      onClick={handleCloseLargePhoto}
                    >
                      &times;
                    </span>
                  </div>
                </Col>
              </Row>

              {isViewingProfile && (
                <>
                   
                  <div className="row profile-details m-0">

                    <div
                      className="col-12 col-md-3"
                      style={{
                        justifyContent: 'start',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '19px 0px 0px 0px',
                      }}
                    >
                      <img
                        src={`${imgPath}/${record1.image}`}
                        alt={`${record1.en_fname}'s Photo`}
                        style={{ height: '250px', backgroundColor: 'white' }}
                        className="small-photo mb-2 border"
                      />
                      

                    
                        <>
                          
                        
                          <img
                            src={`${paymentImgPath}/${record1.tax_image}`}
                            alt={`${record1.en_fname}'s Payment Screen Shot`}
                            style={{
                              height: '400px',
                              backgroundColor: 'white',
                            }}
                            className="smallPhoto mb-2 border"
                          />


                          <div
                            className="col-12 p-2 border"
                            style={{ backgroundColor: 'white' }}
                          >
                            <p>
                              <span style={{fontWeight:"550"}}>UTR No./UPI No./Transaction Transfer No: </span>
                              {record1.taxid}
                            </p>
                            <p><span style={{fontWeight:"550"}}>Transaction Date : </span>{record1.pay_date}</p>
                            <p><span style={{fontWeight:"550"}}>Transaction Amount : </span>{record1.pay_amount} ₹</p>
                          </div>
                        </>
                     
                    </div>

                    <div className="col-12 col-md-9 pt-3">
                      {/* Basic Information */}
                      <div
                        className="row m-0 border"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>Basic Information/मुलभूत माहिती</h6>
                        </div>
                        <div
                          className="col-12 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>First Name (पहिले नाव)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_fname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Middle Name(मधले नाव)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_mname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Last Name (आडनाव)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_lname}
                            </div>
                          </div>
                        </div>

                        {/* <div
                          className="col-12 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>पहिले नाव (मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_fname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>मधले नाव (मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_mname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>आडनाव (मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_lname}
                            </div>
                          </div>

                        </div> */}

                        <div
                          className=" col-12 d-flex p-2"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Gender/लिंग</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.gender === 1 ? 'Male' : 'Female'}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Marital Status/वैवाहिक स्थिती</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : 
                              {record1.marital_status}
                              {/* {record1.marital_status == '1'
                                ? 'Unmarried(Single)'
                                : record1.marital_status == '2'
                                ? 'Divorcee'
                                : record1.marital_status == '3'
                                ? 'Widow'
                                : 'Unknown Marital Status'} */}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Complextion/रंग</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.complextion}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Disability/दिव्यांग</p>
                            </div>
                            <div className="ccol-6 col-md-8">
                              : {record1.disability == '1' ? 'No' : 'Yes'}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Date of birth/जन्मतारीख</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.dob}{' '}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Birth time/जन्मवेळ</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.birth_time}{' '}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Height/उंची</p>
                            </div>
                            <div className="col-6 col-md-8">
                              {' '}
                              : {record1.height}{' '}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Subcast/पोटजात</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.subcastmr_name}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Education Information */}
                      <div
                        className="row m-0 border mt-3"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>
                            Education & Occupation Information/शिक्षण आणि
                            व्यवसाय माहिती
                          </h6>
                        </div>
                        <div
                          className=" col-12 d-flex p-2"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-6 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Education/शिक्षण</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.education_name}
                            </div>
                          </div>
                          <div className="col-12 col-md-6  d-flex">
                            <div className="col-6 col-md-4 para">
                              <p style={{ lineHeight: '18px' }}>
                                Education Category/शिक्षण श्रेणी
                              </p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.subeducation_name}
                            </div>
                          </div>
                          <div className="col-12 col-md-6  d-flex">
                            <div className="col-6 col-md-4 para">
                              <p style={{ lineHeight: '18px' }}>
                                Occupation Type/ व्यवसायाचा प्रकार
                              </p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.occupation}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Annual Income/वार्षिक उत्पन्न</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.amount}{' '}
                            </div>
                          </div>
                        </div>

                        <div className="row d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Occupation & Addrress/व्यवसाय आणि पत्ता
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                            : {record1.occupation_detail}
                          </div>
                        </div>
                      </div>

                      {/* Family Information */}
                      <div
                        className="row m-0 border mt-3"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>Family Information/कौटुंबिक माहिती</h6>
                        </div>
                        <div
                          className=" col-12 p-2 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Father First Name(वडिलांचे नाव)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_mname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Father Middle Name(वडिलांचे मधले नाव)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_fathermname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Father Last Name(वडिलांचे आडनाव)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_lname}
                            </div>
                          </div>

                          {/* <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>वडिलांचे नाव(मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_fatherfname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>वडिलांचे मधले नाव(मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              :{record1.mr_fathermname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>वडिलांचे आडनाव(मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_fatherlname}
                            </div>
                          </div> */}

                          <div className="col-12 col-md-6 d-flex">
                            <div className="col-6 para">
                              <p>Farm in Acar/शेत जमीन एकर</p>
                            </div>
                            <div className="col-6">: {record1.farm_ac}</div>
                          </div>
                          <div className="col-12 col-md-6 d-flex">
                            <div className="col-6 para">
                              <p>Farm in Guntha/शेत जमीन गुंठे</p>
                            </div>
                            <div className="col-6">: {record1.farm_g}</div>
                          </div>
                          <div className="col-12 d-flex">
                            <div className="col-6 col-md-3 para">
                              <p>Other Property/इतर मालमत्ता</p>
                            </div>
                            <div className="col-6 col-md-9">
                              : {record1.other_property}
                            </div>
                          </div>
                        </div>
                        <div
                          className=" col-12 d-flex p-2"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-6">
                            <div className="col-12 para">
                              <p>Brother/भाऊ:</p>
                            </div>
                            <div
                              className="d-flex"
                              style={{ textAlign: 'center' }}
                            >
                              <div className="col-6">
                                Unmarried/अविवाहित : {record1.b_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {record1.b_married}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="col-12 para">
                              <p>Sister/बहीण:</p>
                            </div>
                            <div
                              className="d-flex"
                              style={{ textAlign: 'center' }}
                            >
                              <div className="col-6">
                                Unmarried/अविवाहित : {record1.s_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {record1.s_married}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Contact Information */}
                      <div
                        className="row m-0 border mt-3"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>Contact Information/संपर्क माहिती</h6>
                        </div>
                        <div
                          className="col-12 p-2 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-7 d-flex">
                            <div className="col-3  para">
                              <p>Address(पत्ता)</p>
                            </div>
                            <div className="col-9 ps-2">
                              : {record1.en_address}{' '}
                            </div>
                          </div>
                          <div className="col-12 col-md-5 d-flex">
                            <div className="col-4 para ms-md-2">
                              <p>Village/City(गाव/शहर)</p>
                            </div>
                            <div className="col-8 ps-3">
                              : {record1.en_village}
                            </div>
                          </div>

                          {/* <div className="col-12 col-md-7 d-flex">
                            <div className="col-3 para">
                              <p>पत्ता(मराठी)</p>
                            </div>
                            <div className="col-9 ps-2">
                              : {record1.mr_address}
                            </div>
                          </div>
                          <div className="col-12 col-md-5 d-flex">
                            <div className="col-4 para ms-md-2">
                              <p>गाव/शहर(मराठी)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.mr_village}{' '}
                            </div>
                          </div> */}

                        </div>

                        <div
                          className=" col-12 p-2 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>State(राज्य)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.stateenname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>District(जिल्हा)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.districtenname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>Taluka(तालुका)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.talukaenname}
                            </div>
                          </div>

                          {/* <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>राज्य(मराठी)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.statemrname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>जिल्हा(मराठी)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.districtmrname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>तालुका(मराठी)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.talukamrname}
                            </div>
                          </div> */}

                        </div>
                        <div
                          className=" col-12 d-flex p-2"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p style={{ lineHeight: '18px' }}>Email/ईमेल</p>
                            </div>
                            <div className="col-8">
                              <p>: {record1.emailid}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p style={{ lineHeight: '18px' }}>
                                Mobile No-1/मोबाईल नंबर-1
                              </p>
                            </div>
                            <div className="col-8">
                              <p>: {record1.mobileno}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p style={{ lineHeight: '18px' }}>
                                Mobile No-2/मोबाईल नंबर-2
                              </p>
                            </div>
                            <div className="col-8">
                              <p>: {record1.mobileno2}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Expectations about spouse */}
                      <div
                        className="row m-0 border mt-3 mb-3"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>
                            Expectations About Spouse/जोडीदाराबद्दल अपेक्षा
                          </h6>
                        </div>
                        <div className=" col-12 p-2">
                          <div className="row d-flex">
                            <div className="col-5 col-md-3  para">
                              <p>Expectations/अपेक्षा:</p>
                            </div>
                            <div className="col-7 col-md-9">
                              {' '}
                              <p>
                                {record1.exp_housewife === 1 ? 'Housewife' : null}{' '}
                                &nbsp;{' '}
                                {record1.exp_compatible === 1 ? 'Compatible' : null}{' '}
                                &nbsp;{' '}
                                {record1.exp_farmer === 1 ? 'Farmer' : null}{' '}
                                {record1.exp_serviceman === 1 ? 'Service' : null}{' '}
                                &nbsp;{' '}
                                {record1.exp_businessman === 1
                                  ? 'Business'
                                  : null}{' '}
                                &nbsp;{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </>
              )}

             

              {/* {showOperationButtons && (
                <div className="operation-buttons">
                  <button
                    className="approveBtn"
                    onClick={() => handleOperationClick('approve')}
                    disabled={ !paymentCheckbox}
                  >
                    Payment Approve
                  </button>
                  <button
                    className="rejectBtn"
                    onClick={() => handleOperationClick('reject')}
                    disabled={ !paymentCheckbox}
                  >
                    Payment Reject
                  </button>
                </div>
              )} */}
                
          
              {shouldDisplayButtons && (
                  <>
                    <div className="row p-0 m-0">
                    <>
                      <div className="col-12 col-sm-4"></div>

                      <div
                        className="col-12 col-sm-4 text-center p-1"
                        style={{ backgroundColor: '#660505', color: 'white' }}
                      >
                        <label className="pe-3">Payment Check</label>
                        <input
                          type="checkbox"
                          checked={paymentCheckbox}
                          onChange={() => handleCheckboxChange('payment')}
                        />
                      </div>

                      <div className="col-12 col-sm-4"></div>
                    </>
                    </div>

                    <div className="operation-buttons pb-5">
                      <button
                        className="approveBtn1"
                        onClick={() => handleApprove('payment')}
                        disabled={!paymentCheckbox}
                        style={approveButtonStyles(!paymentCheckbox)}
                      >
                        {loading1 ? <span>Approving...</span> : 'Payment Approve'}
                      </button>
                      <button
                        className="rejectBtn1"
                        onClick={() => handleReject('payment')}
                        disabled={!paymentCheckbox}
                        style={rejectButtonStyles(!paymentCheckbox)}
                      >
                        Payment Reject
                      </button>
                    </div>
                  </>
                )}
             

             {/* Conditional rendering for widow and disability */}
              {(selectedMember?.marital_status == 'Widow' && selectedMember?.gender == '2' && selectedMember?.disability == 1) && (
              
                  <>
                    <div className="row p-0 m-0">
                    <>
                      <div className="col-12 col-sm-4"></div>

                      <div
                        className="col-12 col-sm-4 text-center p-1"
                        style={{ backgroundColor: '#660505', color: 'white' }}
                      >
                        <label className="pe-3">Widow Check</label>
                        <input
                          type="checkbox"
                          checked={widowCheckbox}
                          onChange={() => handleCheckboxChange('widow')}
                        />
                      </div>

                      <div className="col-12 col-sm-4"></div>
                    </>
                    </div>

                    <div className="operation-buttons pb-5">
                      <button className="approveBtn1" onClick={() => handleApprove('marital_status')} disabled={!widowCheckbox}  style={approveButtonStyles(!widowCheckbox)}>
                      {loading1 ? <span>Approving...</span> : 'Approve Marital Status'}
                      </button>
                      <button className="rejectBtn1" onClick={() => handleReject('marital_status')} disabled={!widowCheckbox}  style={rejectButtonStyles(!widowCheckbox)}>Reject Marital Status</button>
                    </div>
                 </>
              )}

              {selectedMember?.disability == '2' && (

                <>
                    <div className="row p-0 m-0">
                      <>
                        <div className="col-12 col-sm-4"></div>

                        <div
                          className="col-12 col-sm-4 text-center p-1"
                          style={{ backgroundColor: '#660505', color: 'white' }}
                        >
                          <label className="pe-3">Disabled Check</label>
                          <input
                            type="checkbox"
                            checked={disabilityCheckbox}
                            onChange={() => handleCheckboxChange('disability')}
                          />
                        </div>

                        <div className="col-12 col-sm-4"></div>
                      </>
                    </div>

                    <div className="operation-buttons pb-5">                     
                        <button className="approveBtn1" onClick={() => handleApprove('disability')} disabled={!disabilityCheckbox}  style={approveButtonStyles(!disabilityCheckbox)}>
                        {loading1 ? <span>Approving...</span> : 'Approve Disability'}
                        </button>
                        <button className="rejectBtn1" onClick={() => handleReject('disability')} disabled={!disabilityCheckbox}  style={rejectButtonStyles(!disabilityCheckbox)}>Reject Disability</button>
                    </div>
                </>
              )}

            {/* Bootstrap Modal */}
            <div
              className={`modal fade ${showModal ? 'show' : ''}`}
              tabIndex="-1"
              role="dialog"
              style={{ display: showModal ? 'block' : 'none' }}
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Enter Rejection Reason for {modalType}</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setShowModal(false)}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <input
                      type="text"
                      className="form-control"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                  {/* <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleModalSubmit}
                    >
                      OK
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                  </div> */}
                  <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleModalSubmit}
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? <span className="spinner-border spinner-border-sm"></span> : 'OK'}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setShowModal(false)}
                        disabled={loading} // Optionally disable the cancel button while loading
                      >
                        Cancel
                      </button>
                    </div>
                </div>
              </div>
            </div>

            {/* Backdrop */}
            {showModal && <div className="modal-backdrop fade show"></div>}
            
                  

            </div>
          </div>
        )}
      </div>
      <div style={footerStyle} className="mt-5 fixed-bottom">
        &copy; 2024-25 Jain Boarding Sangli | Managed By : TechnoAarv Solution
      </div>
    </>
  )
}

export default PaymentApproval
