import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../UserComponents/UserComponent.css';
import { useNavigate } from 'react-router-dom';
import { green } from '@mui/material/colors';
import afterlogo from '../../assets/login-after.png';
import Card from 'react-bootstrap/Card';
import "../MemberEntry/MemberEntryCSS.css";
import axios from 'axios';
import { toast } from 'react-toastify';
import { set } from 'react-hook-form';
import { imgPath, RegistrationSelect } from '../../AllApi/AdminApi/AdminApi';
import AdminMasterFooter from '../../CommonComponenets/AdminMasterFooter';
import EntryHeader from '../../CommonComponenets/EntryHeader';
import { Table } from 'react-bootstrap';



const AdminFourthPage = () => {

 const [disability,setdisability]=useState(false)
 const [lockDateOk,setlockDateOk]=useState(false)   

  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const member_id = userdata.member_id


  const [Userdata, setUserdata] = useState()



  const getUserData = () => {


    axios.get(`${RegistrationSelect}${member_id}`)
      .then((res) => {
        if (res.data.json !== null) {
          setUserdata(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUserData()
  }, [])

  // -----------handle checkbox-----------
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleNextClick = () => {

    if (isChecked) {
      navigate('/admin/payp');     
    }
    else{
      navigate('/admin/aforthp')
    }
  }

  const regFun = () => {

          const state = 1
          navigate('/user/regForm', { state: { state } })
      }

  const regPrint = () => {
    navigate('/payment')
  }


  //--------------------------CSS---------------------------------
  const style3 = { lineHeight: "15px", fontSize: "12px" }
  const style = { fontSize: "12px", width: "100%", padding: "2px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
  const style2 = { border: "1px solid #f796ec", padding: "3px 5px", alignItems: "center", height: "65px", margin: "0px 1px 0px 1px", textAlign: "start" }
  const style4 = { display: "flex", lineHeight: "8px", fontSize: "12px", justifyContent: "center" }
  const style6 = { display: "flex", lineHeight: "8px", fontSize: "12px", justifyContent: "center" }
  const style5 = { display: "flex", lineHeight: "12px", fontSize: "12px", justifyContent: "center", flexDirection: "column" }

  return (

    <Container fluid className='m-0 p-0'>
      <div className='m-0 p-0'>
        <EntryHeader />
      </div>

      <Container className='container-fourth4 rounded p-3 my-3 p-md-5 mb-5'>
        <div className='rounded shadow  px-3 pb-2 py-sm-5' style={{ backgroundColor: "white" }} >
          <Row className='mb-2'>
            <Col>
              <div className='mt-5 mt-md-0' style={{ display: "flex", justifyContent: "center", textAlign: "center", color: "red" }}>
                <h5><b>The following text is to be printed in the main booklet as it is. Please make sure before proceeding... <br /> (खालील मजकूर आहे तसा मुख्य पुस्तिकेमध्ये छापला जाणार आहे. कृपया पुढे जाण्याअगोदर खात्री करावी...)</b></h5>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={afterlogo} />
              </div>
            </Col>
          </Row>



          {
            Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

              return (
                <>

                <div className="row m-0 p-0" style={{overflow:"auto"}}>
                    <div className='row p-0 m-0'  >
                <div className='col-12 p-0 ' style={{ backgroundColor: "white" }} >
                  <div className='py-3' style={{ backgroundColor: "#f796ec", display: "flex", alignItems: "center",padding:"0px",margin:"0px",height:"5px" }}>
                    <div className='col-3'></div>
                    <div className='col-9 ' style={{ alignItems: "center", display: "flex" ,fontSize:"12px"}}>
                      <div className='col-9' style={{ margin: "0px 0px 0px -70px", alignItems: "end",justifyContent:"center",alignItems:"center",fontSize:"15px" }}><b>{value.mr_fname}/{' '}{value.mr_fatherfname}{' '}{value.mr_fathermname}{' '} {value.mr_fatherlname}</b></div>

                      <div className='col-1 me-5 ' style={{ textAlign: "center", border: "1px solid white", borderRadius: "10px", backgroundColor: "white", margin: "0px 0px 0px 10px" }}><label style={{ fontWeight: "bold", }}>{value.reg_id}</label></div>
                    </div>
                  </div>

                  <div className=' d-flex p-0' style={{ border: "1px solid #c42fb2", borderTopColor: "#f796ec",  backgroundColor: "white" }}>
                    <div className='col-2 p-2' style={{ justifyContent: "center", display: "flex" }}>
                      <img src={`${imgPath}/${value.image}`} alt={`${value.mr_fname}'s Photo`} style={{ width: "100%", height: "155px", border: "1px solid grey" }} />
                    </div>

                    <div className='col-10 px-4 pt-2'>
                      <div className='row p-0'>
                        <div className='col-8 p-0 pt-1' >
                          <p style={{ fontSize: "12px", lineHeight: "13px",maxLines:"50", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}><b>पत्ता : </b>{' '} {value.mr_address}{' '}&nbsp;<b>गाव/ शहर : </b>{value.mr_village}{' '}&nbsp;<b>ता : </b>{value.talukamrname}{' '}&nbsp;<b>जि : </b>{value.districtmrname} {' '}&nbsp;<b>रा : </b>{value.statemrname}</p>
                          <p style={{ fontSize: "12px", lineHeight: "7px" }}><b>मो : </b>{' '}{value.mobileno}/{value.mobileno2}</p>
                          <p style={{ fontSize: "12px", lineHeight: "12px",maxLines:"50", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",marginTop:"-5px"}}><b>व्यवसाय : </b>{' '}{value.occupation},{value.occupation_detail}</p>
                        </div>

                        <div className='col-4 p-0' style={{ alignItems: "end", display: "flex", flexDirection: "column" }}>
                                
                                    <div className='d-flex mb-1' style={{ fontSize: "12px", lineHeight: "20px", }}>

                                      <div style={{ padding: "5px 15px", color: "white", width: "50px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>भाऊ:</b></div>

                                      <div style={{ width: "180px", display: "flex", padding: "5px 10px", border: "1px solid #f796ec"}} >
                              
                                      
                                          <span style={{ fontSize: "12px" }}><b>विवाहित-</b>{value.b_married > 0 ? value.b_married: '-' }</span>
                                        
                                        
                                          <span style={{ fontSize: "12px" }} className='mx-2'><b> अविवाहित-</b>{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                                      

                                
                                      </div>
                                      
                                    </div>
                                  

                                    <div className='d-flex mt-1' style={{ fontSize: "12px", lineHeight: "20px", }}>

                                      <div style={{ padding: "5px 15px", color: "white", width: "50px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>बहीण:</b></div>

                                      <div style={{ width: "180px", display: "flex", padding: "5px 10px", border: "1px solid #f796ec"}} >
                              
                                      
                              <span style={{ fontSize: "12px" }}><b>विवाहित-</b>{value.s_married > 0 ? value.s_married: '-' }</span>
                            
                            
                              <span style={{ fontSize: "12px" }}  className='mx-2'><b> अविवाहित-</b>{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span>
                          

                    
                          </div>
                                    </div>
                                  
                              </div>
                      </div>


                      <div className="row p-0 d-flex mb-2 mb-0">
                        <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                          <div style={style}> <b>जन्मतारीख व वेळ</b></div>
                          <div style={style2} > <p style={style4} className='pt-1'>{value.dob}</p> <p style={style4}>{value.birth_time}</p></div>
                        </div>

                        <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                          <div style={style}><b>उंची व वर्ण</b></div>
                          <div style={style2}> <p style={style4} className='pt-1'>{value.height}</p> <p style={style4} >{value.complextion}</p></div>
                        </div>

                        <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                          <div style={style}><b>शिक्षण</b></div>
                          <div style={style2}> <h6 style={style5} className='text-center pt-1'>{value.subeducation_name},&nbsp;<p className='text-center pt-1' style={style5}>{value.other_education}</p></h6>
                          </div>
                        </div>

                        <div className='col-3 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                          <div style={style}><b>वार्षिक उत्पन्न/मालमत्ता</b></div>
                          <div style={style2}> <h6 style={style4} className='pt-1'>{value.amount}</h6>
                            {value.farm_g > 0 || value.farm_ac > 0 ? (
                              <>
                                <h6 style={style4} className='pt-1'><b>शेती -&nbsp;</b>
                                  {value.farm_ac > 0 ? (
                                    <span> {value.farm_ac}&nbsp; एकर{' '}</span>
                                  ) : null}
                                  {value.farm_g > 0 ? (
                                    <span style={style3}>{' '} &nbsp; {value.farm_g} &nbsp; गुंठे{' '}</span>
                                  ) : null
                                  }
                                </h6>
                              </>
                            ) : null}

                            <span className='text-center' style={style6}>
                              {value.other_property && value.other_property.length > 29
                                ? value.other_property.slice(0, 29)
                                : value.other_property}
                              </span></div>
                        </div>

                        <div className='col-1 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                          <div style={style}><b>पोटशाखा</b></div>
                          <div style={style2}> <p style={style4} className='pt-1'>{value.subcastename}</p></div>
                        </div>

                        <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                          <div style={style}><b>अपेक्षा</b></div>
                          <div style={style2}> <p  style={{ lineHeight: "24px", fontSize: "12px", display: "flex", flexDirection: "column", textAlign: "center", margin: "0px 20px" }}>{' '}{value.exp_housewife === 1 ? 'गृहिणी ' : null}{' '}{value.exp_compatible === 1 ? 'अनुरूप ' : null}{' '}{value.exp_farmer === 1 ? 'शेती ' : null}{' '}{value.exp_serviceman === 1 ? 'नोकरी ' : null}{' '}{value.exp_businessman === 1 ? 'व्यवसाय ' : null}</p></div>
                        </div>

                      </div>
                    </div>
                  </div>


                </div>
                    </div>
                </div>
                      
                    </>
                  )
                })}
       
     
          {/* ---------second row------------ */}
         
          <div className='row m-0 p-0 m-sm-0 pt-2 px-md-3 d-flex'>
            <div className='col-1 px-2 pt-5 '>
              <input className='me-3'
                type="checkbox"
                name="check"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className='col-11 px-2 pt-5'>
              <p><b>I agree to all the rules of the said melava and I will follow them. I assure you that the information filled in the said form is completely true and you can print it in your Parichay Pustika or give it to the prospective candidates. I will be fully responsible for that. The management or the board will not be responsible for the same. I will not allow all the office bearers of the bride and groom gathering to be harmed by any dispute. <br /> (सदर मेळाव्या विषयाचे सर्व नियम मला मान्य आहेत व मी त्याचे पालन करेन. मी आपणास असे आश्वासित करतो की सदर फॉर्म मध्ये भरलेली माहिती पूर्णतः खरी असून ती आपण आपल्या परिचय पुस्तिकेत छापू शकता किंवा अपेक्षित उमेदवारांना देऊ शकता. त्यासाठी मी पूर्णतः जबाबदार राहीन. संयोजक अथवा मंडळ त्यासाठी जबाबदार राहणार नाही. मी वधू-वर मेळाव्याचे सर्व पदाधिकारी यांना कोणत्याही विवादामुळे नुकसान होऊ देणार नाही.)</b></p>
            </div>

            <div className='col-12 ms-md-5'>
              <button className='rounded px-3 py-1 ms-md-4'
                
                onClick={handleNextClick}
                disabled={!isChecked}
              >
                Confirm and proceed to payment
              </button>
            </div>

          </div>


        </div>
      </Container>
      <div><AdminMasterFooter /></div>
    </Container>

  )
}

export default AdminFourthPage




