import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button, Spinner } from 'react-bootstrap';
import {
  EducationMaster,
  SubeducationMaster,
  subcastMaster,
  IncomeMaster,
  RegsitrationSave,
  StateMaster,
  DistrictMaster,
  TalukaMaster,
  RegistrationSelect,
  RegistrationUpdateProfile,
} from "../../AllApi/UserApi/UserApi";
import { useState, useEffect } from "react";
import axios from "axios";
import "../../UserComponents/UserComponent.css";
import { BaseIP } from "../../AllApi/UserApi/UserApi";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const FirstPage = (props) => {

  useEffect(() =>{
    window.scrollTo(0, 0);
  },[])

  // basic information
  const data = JSON.parse(sessionStorage.getItem("status"));

  const [Registrationid, setRegistrationid] = useState();
  const reg_id = JSON.parse(sessionStorage.getItem("Userid"));
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  
  const gender1 = userdata.gender;
  const Email1 = userdata.email;
  const Mobno = userdata.mob_no;
  const FNameEn = userdata.fname;
  const MNameEn = userdata.mname;
  const LNameEn = userdata.lname;
  const dofb = userdata.dateofbirth;
  const FaFNameEn1 = userdata.mname;
  const FaLNameEn1 = userdata.lname;
  const member_id = userdata.id;
  //----------Registration Form basic information data save------------//

  const [Gender, setGender] = useState();
  const [Maritialsatatus, setMaritialsatatus] = useState();
  const [Disability, setDisability] = useState();
  const [Complextion, setComplextion] = useState();
  const [Enfname, setEnfname] = useState();
  const [Enmname, setEnmname] = useState();
  const [Enlname, setEnlname] = useState();
  const [Mrfname, setMrfname] = useState();
  const [Mrmname, setMrmname] = useState();
  const [Mrlname, setMrlname] = useState();
  const [Dateob, setDateob] = useState();
  const [BirthTime, setBirthTime] = useState();
  const [Height, setHeight] = useState();
  const [SubCaste, setSubCaste] = useState();
  

  const [Education, setEducation] = useState();
  const [EduCategory, setEduCategory] = useState([]);
  const [othereducation, setOthereducation] = useState(null);
  const [EduType, setEduType] = useState();
  const [OccupationDetail, setOccupationDetail] = useState();
  const [AnnualIncome, setAnnualIncome] = useState();

  const [AddressEn, setAddressEn] = useState();
  const [AddressMr, setAddressMr] = useState();
  const [CityEn, setCityEn] = useState();
  const [CityMr, setCityMr] = useState();
  const [Taluka, setTaluka] = useState();
  const [District, setDistrict] = useState();
  const [State, setState] = useState();
  const [Email, setEmail] = useState();
  const [Mobno1, setMobno1] = useState();
  const [Mobno2, setMobno2] = useState();

  const [FaFNameEn, setFaFNameEn] = useState();
  const [FaMNameEn, setFaMNameEn] = useState();
  const [FaLNameEn, setFaLNameEn] = useState();
  const [FaFNameMr, setFaFNameMr] = useState();
  const [FaMNameMr, setFaMNameMr] = useState();
  const [FaLNameMr, setFaLNameMr] = useState();
  const [FarmAcar, setFarmAcar] = useState();
  const [FarmGuntha, setFarmGuntha] = useState();
  const [OtherProperty, setOtherProperty] = useState(null);
  const [MarriedBro, setMarriedBro] = useState();
  const [UnMarriedBro, setUnMarriedBro] = useState();
  const [MarriedSis, setMarriedSis] = useState();
  const [UnMarriedSis, setUnMarriedSis] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [EducationData, setEducationData] = useState([]);
  const [SubeducationData, setSubeducationData] = useState([]);
  const [SubCastData, setSubCastData] = useState([]);
  const [IncomeData, setIncomeData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [DistictData, setDistictData] = useState([]);
  const [TalukaData, setTalukaData] = useState([]);

  const [isLoading, setIsLoading] = useState(false); // Add isLoading state


  const handalmrmname = (e) => {
    setMrmname(e.target.value);
    setFaFNameMr(e.target.value);
  };
  const handalmrlname = (e) => {
    setMrlname(e.target.value);
    setFaLNameMr(e.target.value);
  };

  const handalenmname = (e) =>{
     setEnmname(e.target.value);
     setFaFNameEn(FaFNameEn1);
  };

  const handalenlname = (e) => {
    setEnlname(e.target.value);
    setFaLNameEn(FaLNameEn1);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setIsLoading(true)

    const idArray = EduCategory.map((item) => item.id);
    const newEduCategory = idArray.join(", ");

    if(newEduCategory!==""){
    const regdata = {
      gender: gender1,
      marital_status: Maritialsatatus,
      disability: Disability,
      complextion: Complextion,
      en_fname: FNameEn,
      en_mname: MNameEn,
      en_lname: LNameEn,
      mr_fname: FNameEn, //new
      mr_mname: MNameEn, //new
      mr_lname: LNameEn, //new
      // mr_fname: Mrfname,
      // mr_mname: Mrmname,
      // mr_lname: Mrlname,
      dob: dofb,
      birth_time: BirthTime,
      height: Height,
      subcaste_id: SubCaste,
      edu_id: Education,
      subedu_id: newEduCategory,
      other_education: othereducation,
      other_education: othereducation,
      occupation: EduType,
      occupation_detail: OccupationDetail,
      income_id: AnnualIncome,
      en_address: AddressEn,
      mr_address: AddressEn,  //new
      // mr_address: AddressMr,
      en_village: CityEn,
      mr_village: CityEn, //new
      // mr_village: CityMr,
      taluka_id: Taluka,
      district_id: District,
      state_id: State,
      emailid: Email1,
      mobileno: Mobno,
      mobileno2: Mobno2,
      en_fatherfname: FaFNameEn1,
      en_fathermname: FaMNameEn,
      en_fatherlname: FaLNameEn1,
      mr_fatherfname: FaFNameEn1, //new
      mr_fathermname: FaMNameEn, //new
      mr_fatherlname: FaLNameEn1, //new
      // mr_fatherfname: FaFNameMr,
      // mr_fathermname: FaMNameMr,
      // mr_fatherlname: FaLNameMr,
      farm_ac: FarmAcar,
      farm_g: FarmGuntha,
      other_property: OtherProperty,
      b_married: MarriedBro,
      b_unmarried: UnMarriedBro,
      s_married: MarriedSis,
      s_unmarried: UnMarriedSis,
      member_id: member_id,
      // disability_status: 1,
    };
    console.log("regdata==>>>", regdata);

    axios
      .post(RegsitrationSave, regdata)
      .then((res) => {
        setIsLoading(false);
        if (res.data.message === "SAVED") {
          const userdata = res.data.userdata;

          sessionStorage.setItem("Userid", JSON.stringify(userdata.reg_id));
          // alert("Saved Successfully")
          toast.success("Saved Successfully !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setSubmitted(true);
          sessionStorage.setItem("status", 4);
          props.onSubmit();

          // setReadOnly1(true)
        }
      })
    
      .catch((err) => console.log(err));
    }
    else{
      toast.error("Fill Education Please !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  //UpdateApi
  const updatedata = () => {
    setIsLoading(true)
    const idArray = EduCategory.map((item) => item.id);
    const newEduCategory = idArray.join(", ");
  
    if(newEduCategory!==""){
    const regdata = {
      reg_id: Registrationid,
      gender: gender1,
      marital_status: Maritialsatatus,
      disability: Disability,
      complextion: Complextion,
      en_fname: FNameEn,
      en_mname: MNameEn,
      en_lname: LNameEn,
      mr_fname: FNameEn, //new
      mr_mname: MNameEn, //new
      mr_lname: LNameEn, //new
      // mr_fname: Mrfname,
      // mr_mname: Mrmname,
      // mr_lname: Mrlname,
      dob: dofb,
      birth_time: BirthTime,
      height: Height,
      subcaste_id: SubCaste,
      edu_id: Education,
      subedu_id: newEduCategory,
      other_education: othereducation,
      occupation: EduType,
      occupation_detail: OccupationDetail,
      income_id: AnnualIncome,
      en_address: AddressEn,
      mr_address: AddressEn, //new
      // mr_address: AddressMr,
      en_village: CityEn,
      mr_village: CityEn, //new
      // mr_village: CityMr,
      taluka_id: Taluka,
      district_id: District,
      state_id: State,
      emailid: Email1,
      mobileno: Mobno,
      mobileno2: Mobno2,
      en_fatherfname: FaFNameEn1,
      en_fathermname: FaMNameEn,
      en_fatherlname: FaLNameEn1,
      mr_fatherfname: FaFNameEn1, //new
      mr_fathermname: FaMNameEn, //new
      mr_fatherlname: FaLNameEn1, //new
      // mr_fatherfname: FaFNameMr,
      // mr_fathermname: FaMNameMr,
      // mr_fatherlname: FaLNameMr,
      farm_ac: FarmAcar,
      farm_g: FarmGuntha,
      other_property: OtherProperty,
      b_married: MarriedBro,
      b_unmarried: UnMarriedBro,
      s_married: MarriedSis,
      s_unmarried: UnMarriedSis,
      member_id: member_id,
    };
    axios
      .put(RegistrationUpdateProfile, regdata)
      .then((res) => {
        setIsLoading(false);
        if (res.data.message === "UPDATED") {
          // alert("Profile UPDATED Successfully");
          toast.success("Profile Updated Succesfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
         
          setIsEditing(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // alert("Failed To Update Package, try again");
        toast.error("Failed To Update Package, try again!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    }
    else{
      toast.error("Fill Education Please !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const [update, setUpdate] = useState(false);
  //Get Api Call
  const convertToHHmm = (timeStr) => {
    // Create a Date object with the given time string
    const date = new Date(`2000-01-01T${timeStr}`);

    // Get the hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the time as "HH:mm"
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };



  const getdata = (SubeducationData) => {
    axios
      .get(`${RegistrationSelect}${member_id}`)
      
      .then((res) => {
        if (res.data.json !== null) {
          const api = res.data;
          const getSubeducationNameById = (id) => {
           
            const matchingOption = SubeducationData.find((option) => option.id === id);
           
            return matchingOption && matchingOption.subeducation_name ? matchingOption.subeducation_name : "Not Found";

         };
        
          api.map((api, index) => {
            setRegistrationid(api.reg_id);
            setGender(api.gender);
            setMaritialsatatus(api.marital_status);
            setDisability(api.disability);
            setComplextion(api.complextion);
            setEnfname(api.en_fname);
            setEnmname(api.en_mname);
            setEnlname(api.en_lname);
            setMrfname(api.mr_fname);
            setMrmname(api.mr_mname);
            setMrlname(api.mr_lname);
            setDateob(api.dob);
            setFaFNameEn(api.en_mname);
            setFaLNameEn(api.en_lname);
            

            const timeParts = api.birth_time.split(" ");

            if (timeParts.length === 2) {
              // Extract hours, minutes, and AM/PM
              const [time, ampm] = timeParts;
              const [hoursStr, minutesStr] = time.split(":");

              // Convert hours to 24-hour format
              let hours = parseInt(hoursStr, 10);
              if (ampm === "PM" && hours !== 12) {
                hours += 12;
              } else if (ampm === "AM" && hours === 12) {
                hours = 0;
              }

              // Format the time as "HH:mm"
              const formattedTime = `${hours
                .toString()
                .padStart(2, "0")}:${minutesStr}`;

              // Set the formatted time as the initial value
              setBirthTime(formattedTime);
            }

            //setBirthTime(time)

            setHeight(api.height);
            setSubCaste(api.subcaste_id);
            setEducation(api.edu_id);
            const subidarray = api.subedu_id.match(/[^,]+/g);
            const subid = subidarray.map((value) => (
             
              {
             
              id: parseInt(value),
              subeducation_name: getSubeducationNameById(parseInt(value)), // You need to implement this function
            })
            );
     
            setEduCategory(subid);
            setEduType(api.occupation);
            setOccupationDetail(api.occupation_detail);
            setAnnualIncome(api.income_id);
            setAddressEn(api.en_address);
            setAddressMr(api.mr_address);
            setCityEn(api.en_village);
            setCityMr(api.mr_village);
            setTaluka(api.taluka_id);
            setDistrict(api.district_id);
            setState(api.state_id);
            setEmail(api.emailid);
            setMobno1(api.mobileno);
            setMobno2(api.mobileno2);
            setFaFNameEn(api.en_fatherfname);
            setFaMNameEn(api.en_fathermname);
            setFaLNameEn(api.en_fatherlname);
            setFaFNameMr(api.mr_fatherfname);
            setFaMNameMr(api.mr_fathermname);
            setFaLNameMr(api.mr_fatherlname);
            setFarmAcar(api.farm_ac);
            setFarmGuntha(api.farm_g);
            setOtherProperty(api.other_property);
            setOthereducation(api.other_education);
            setMarriedBro(api.b_married);
            setUnMarriedBro(api.b_unmarried);
            setMarriedSis(api.s_married);
            setUnMarriedSis(api.s_unmarried);
          });

          setIsEditing(false);
          setisMstatusEditing(false);
          setisDstatusEditing(false);

          setUpdate(true);
        }
      })
      .catch((error) => {
        // alert("failed to fetch data");
      });
  };

  const [isEditing, setIsEditing] = useState(true);
  const [isMstatusEditing, setisMstatusEditing] = useState(true);
  const [isDstatusEditing, setisDstatusEditing] = useState(true);

  const handleEditClick = () => {
    setisMstatusEditing(false);
    setisDstatusEditing(false);
    setIsEditing(true);
    setUpdate(true);
  };

  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    setUserStatus(data);
    if (data > 3) {
      getdata(SubeducationData);
    }
  }, [data,SubeducationData]);

  //master use state and other code

  const status = JSON.parse(sessionStorage.getItem("status"));

  const getEducationData = () => {
    axios
      .get(EducationMaster)
      .then((res) => {
        if (res.data !== null) {
          setEducationData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubeducationData = () => {
    axios
      .get(`${SubeducationMaster}/${Education}`)
      .then((res) => {
        if (res.data !== null) {
          setSubeducationData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubCastData = () => {
    axios
      .get(subcastMaster)
      .then((res) => {
        if (res.data !== null) {
          setSubCastData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getIncomeData = () => {
    axios
      .get(IncomeMaster)
      .then((res) => {
        if (res.data !== null) {
          setIncomeData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStateData = () => {
    axios
      .get(StateMaster)
      .then((res) => {
        if (res.data !== null) {
          setStateData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDistrictData = () => {
    axios
      .get(`${DistrictMaster}${State}`)

      .then((res) => {
        if (res.data !== null) {
          setDistictData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTalukaData = () => {
    axios
      .get(`${TalukaMaster}${State}/${District}`)

      .then((res) => {
        if (res.data !== null) {
          setTalukaData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEducationData();
    getSubeducationData();
    getSubCastData();
    getIncomeData();
    getStateData();
  }, [Education]);
  useEffect(() => {
    getDistrictData();
    getTalukaData();
  }, [State, District]);

 

  const handleSelectChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setEduCategory(selectedOptions);
  };

  const funFaLNameEn=(e)=>{
    setFaLNameEn(Enlname)
  }

  const funFaFNameEn=(e)=>{
    setFaFNameEn(Enfname)
  }

  //===========new code===========

  const handleNameChange = (setterEnglish, setterMarathi) => (e) => {
    const value = e.target.value;
    setterEnglish(value);
    setterMarathi(value);
  };
  //==============================

  return (
    <div className="m-0 ">
      <Container className="container-first rounded ">
        <div
          className="pe-5 "
          style={{ display: "flex", justifyContent: "end" }}
        >
          {userStatus > 3 ? (
            <button
              className="rounded mb-0 "
              style={{
                padding: "6px 15px",
                backgroundColor: "rgb(233, 224, 227)",
                border: "0px",
                color: "rgb(134, 28, 18)",
                marginBottom: "-40px",
                marginTop: "20px",
                fontSize: "20px",
              }}
              onClick={handleEditClick}
            >
              {" "}
              <b>Edit</b>
            </button>
          ) : null}
        </div>
        <Form onSubmit={handleSave}>
          <div
            className="main-form px-md-5 pb-md-5 pt-md-2  pt-sm-4 pb-sm-4 pb-5"
            style={{ marginTop: "30px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                color: "yellow",
              }}
            >
              <h5>
                <b>
                  All fields are mandatory<span>*</span>
                </b>
              </h5>
            </div>
            <div
              className=" rounded shadow p-3 pb-4  "
              style={{ backgroundColor: "white" }}
            >
              {/* <Form onSubmit={handleSave1}> */}
              <Row style={{ paddingTop: "" }}>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "10px",
                      borderBottom: "1px solid gray",
                      color: "rgb(134, 28, 18)",
                    }}
                  >
                    <div>
                      <h5>
                        <b>Basic Information (उमेदवार माहिती)</b>
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Label>Gender (लिंग):</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={gender1}
                    onChange={(e) => setGender(e.target.value)}
                    required
                    // disabled={isEditing ? false : true}
                    disabled
                  >
                    <option value="">Select Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </Form.Select>
                </Col>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Label>Marital Status (वैवाहिक स्थिती) :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={Maritialsatatus}
                    onChange={(e) => setMaritialsatatus(e.target.value)}
                    required
                    disabled={isMstatusEditing ? false : true}
                  >
                    <option value="">Select Marital Status </option>
                    <option>Unmarried</option>
                    <option>Divorcee</option>
                    <option>Widow</option>
                  </Form.Select>
                </Col>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Label>Disability (दिव्यांग):</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={Disability}
                    onChange={(e) => setDisability(e.target.value)}
                    required
                    disabled={isDstatusEditing ? false : true}
                  >
                    <option value="">Choose..</option>
                    <option value="1">No</option>
                    <option value="2">Yes</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12}>
                  <Form.Label>
                    Candidate Name English (उमेदवाराचे नाव इंग्रजी) :
                  </Form.Label>
                </Col>
                <Col sm={12} md={4}>
                  <Form.Control
                    className="mt-2"
                    placeholder="First Name En"
                    value={FNameEn}
                    onChange={(e) => setEnfname(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Form.Control
                    className="mt-2"
                    placeholder="Middle Name En"
                    value={MNameEn}
                    onChange={(e) => handalenmname(e)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Form.Control
                    className="mt-2"
                    placeholder="Last Name En"
                    value={LNameEn}
                    onChange={(e) => handalenlname(e)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
              </Row>

              {/* <Row style={{ paddingTop: "20px" }}>
                <Col sm={12}>
                  <Form.Label>
                    Name In Marathi (उमेदवाराचे नाव मराठी) :
                  </Form.Label>
                </Col>
                <Col sm={12} md={4}>
                  <Form.Control
                    className="mt-2"
                    placeholder="पहिले नाव"
                    value={Mrfname}
                    onChange={(e) => setMrfname(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Form.Control
                    className="mt-2"
                    placeholder=" मधले नाव"
                    value={Mrmname}
                    onChange={(e) => handalmrmname(e)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Form.Control
                    className="mt-2"
                    placeholder="अडनाव"
                    value={Mrlname}
                    onChange={(e) => handalmrlname(e)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
              </Row> */}

              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} md={3} className="pt-2 pt-md-0">
                  <Form.Label>Date Of Birth (जन्म तारीख) :</Form.Label>
                  <Form.Control
                    type="date"
                    value={dofb}
                    onChange={(e) => setDateob(e.target.value)}
                    required
                    disabled
                  />
                </Col>
                <Col sm={12} md={2} className="pt-2 pt-md-0">
                  <Form.Label>Birth Time(जन्मवेळ) :</Form.Label>
                  <Form.Control
                    type="time"
                    value={BirthTime}
                    onChange={(e) => setBirthTime(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={2} className="pt-2 pt-md-0">
                  <Form.Label>Height (उंची):</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={Height}
                    onChange={(e) => setHeight(e.target.value)}
                    data-validetta="required"
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    <option>Below 4 ft</option>
                    <option>4 ft &nbsp; 06 in</option>
                    <option>4 ft &nbsp; 07 in</option>
                    <option>4 ft &nbsp; 08 in</option>
                    <option>4 ft &nbsp; 09 in</option>
                    <option>4 ft &nbsp; 10 in</option>
                    <option>4 ft &nbsp; 11 in</option>
                    <option>5 ft</option>
                    <option>5 ft &nbsp; 01 in</option>
                    <option>5 ft &nbsp; 02 in</option>
                    <option>5 ft &nbsp; 03 in</option>
                    <option>5 ft &nbsp; 04 in</option>
                    <option>5 ft &nbsp; 05 in</option>
                    <option>5 ft &nbsp; 06 in</option>
                    <option>5 ft &nbsp; 07 in</option>
                    <option>5 ft &nbsp; 08 in</option>
                    <option>5 ft &nbsp; 09 in</option>
                    <option>5 ft &nbsp; 10 in</option>
                    <option>5 ft &nbsp; 11 in</option>
                    <option>6 ft</option>
                    <option>6 ft &nbsp; 01 in</option>
                    <option>6 ft &nbsp; 02 in</option>
                    <option>6 ft &nbsp; 03 in</option>
                    <option>6 ft &nbsp; 04 in</option>
                    <option>6 ft &nbsp; 05 in</option>
                    <option>6 ft &nbsp; 06 in</option>
                    <option>6 ft &nbsp; 07 in</option>
                    <option>6 ft &nbsp; 08 in</option>
                    <option>6 ft &nbsp; 09 in</option>
                    <option>6 ft &nbsp; 10 in</option>
                    <option>6 ft &nbsp; 11 in</option>
                    <option>7 ft</option>
                    <option>Above 7 ft</option>
                  </Form.Select>
                </Col>
                <Col sm={12} md={2} className="pt-2 pt-md-0">
                  <Form.Label>Complextion(रंग) :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={Complextion}
                    onChange={(e) => setComplextion(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    <option value="Fair">Fair - गोरा</option>
                    <option value="Wheatish">Wheatish - गहू </option>
                    <option value="Dark">Dark - सावळा</option>
                  </Form.Select>
                </Col>
                <Col sm={12} md={3} className="pt-2 pt-md-0">
                  <Form.Label>Sub Caste(पोटजात) :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={SubCaste}
                    onChange={(e) => setSubCaste(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    {SubCastData.map((value, index) => {
                      return (
                        <option value={value.id} key={index.id}>
                          {value.subcastename}:{value.subcastmr_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              {/* </Form> */}
            </div>
          </div>

          <div className="px-md-5 pb-sm-4 pb-4">
            <div
              className=" rounded shadow p-3 pb-4  "
              style={{ backgroundColor: "white" }}
            >
              {/* <Form onSubmit={handleSave2}> */}
              <Row style={{ paddingTop: "10px" }}>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "10px",
                      borderBottom: "1px solid gray",
                      color: "rgb(134, 28, 18)",
                    }}
                  >
                    <h5>
                      <b>
                        Education & Occupation Information (शिक्षण आणि व्यवसाय):
                      </b>
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} md={6} className="pt-2 pt-md-0">
                  <Form.Label>
                    Educational Category(शैक्षणिक श्रेणी) :
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={Education}
                    filterSelectedOptions
                    onChange={(e) => setEducation(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    {EducationData.map((value, index) => {
                      return (
                        <option value={value.id} key={index.id}>
                          {value.education_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                
                <Col sm={12} md={6} className="pt-2 pt-md-0">
                  <Form.Label>Education (शिक्षण): <span className="fw-bold">*</span> You can select max. 3 options</Form.Label>
                  <Autocomplete
                    multiple
                    id="movie-tags"
                    value={EduCategory}
                    
                    disabled={isEditing ? false : true}
                    onChange={(event, newValue) => {
                     
                                    if (newValue.length <= 3) {
                                      setEduCategory(newValue);
                                    }
                                    else{
                                      toast.warn('Please Select Only Three Options!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme:'colored'
                     
                      });
                      
                    }}
                    }
                    required
                    

                    options={SubeducationData}
                    getOptionLabel={(option) => option.subeducation_name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          style={{ color: "black" }}
                          label={option.subeducation_name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField   {...params} placeholder="Add Education" />
                    )}
                  />
                </Col>
                
              </Row>              
              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} md={6} className="pt-2 pt-md-0">
                  <Form.Label>Other Qualification(इतर पात्रता) :</Form.Label>
                  <Form.Control
                    value={othereducation}
                    onChange={(e) => setOthereducation(e.target.value)}
                    disabled={isEditing ? false : true}
                  ></Form.Control>
                </Col>
                <Col sm={12} md={6} className="pt-2 pt-md-0">
                  <Form.Label>Occupation Type(व्यवसायाचा प्रकार) :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={EduType}
                    onChange={(e) => setEduType(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    <option>Business</option>
                    <option>Service</option>
                    <option>Student</option>
                    <option>Farmer</option>
                    <option>Other..</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} md={6} className="pt-2 pt-md-0">
                  <Form.Label>
                    Occupation & Address(व्यवसाय आणि पत्ता):
                  </Form.Label>
                  <Form.Control
                    value={OccupationDetail}
                    onChange={(e) => setOccupationDetail(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={6} className="pt-2 pt-md-0">
                  <Form.Label>Annual Income(वार्षिक उत्पन्न) :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={AnnualIncome}
                    onChange={(e) => setAnnualIncome(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    {IncomeData.map((value, index) => {
                      return (
                        <option value={value.id} key={index.id}>
                          {value.amount}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              {/* </Form> */}
            </div>
          </div>

          <div className="px-md-5 pb-sm-4 pb-5 pt-4">
            <div
              className=" rounded shadow p-3 pb-4 "
              style={{ backgroundColor: "white" }}
            >
              {/* <Form onSubmit={handleSave3}> */}
              <Row style={{ paddingTop: "10px" }}>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "10px",
                      borderBottom: "1px solid gray",
                      color: "rgb(134, 28, 18)",
                    }}
                  >
                    <h5>
                      <b>Contact Information (संपर्क माहिती)</b>
                    </h5>
                  </div>
                </Col>
              </Row>              
              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Label>State(राज्य) :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={State}
                    onChange={(e) => setState(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose option</option>
                    {StateData.map((value, index) => {
                      return (
                        <option value={value.id}>
                          {value.stateenname}:{value.statemrname}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Label>District(जिल्हा) :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={District}
                    onChange={(e) => setDistrict(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose option</option>
                    {DistictData.map((value, index) => {
                      return (
                        <option value={value.id}>
                          {value.districtenname}:{value.districtmrname}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Label>Taluka (तालुका) :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={Taluka}
                    onChange={(e) => setTaluka(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose option</option>
                    {TalukaData.map((value, index) => {
                      return (
                        <option value={value.id}>
                          {value.talukaenname}:{value.talukamrname}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                
                <Col sm={12} md={5} className="pt-2 pt-md-0">
                  <Form.Label>
                    Village/City(English) गाव/शहर (इंग्रजी):
                  </Form.Label>
                  <Form.Control
                    sm={12}
                    md={4}
                    value={CityEn}
                    onChange={(e) => setCityEn(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={7} className="pt-2 pt-md-0">
                  <Form.Label>House/Colony/Road (English)पत्ता (इंग्रजी) :</Form.Label>
                  <Form.Control
                    sm={12}
                    md={8}
                    className="place"
                    placeholder="House Name/No/Road/Chowk/Colany/Landmark"
                    value={AddressEn}
                    onChange={(e) => setAddressEn(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
              </Row>

              {/* <Row style={{ paddingTop: "20px" }}>
                
                <Col sm={12} md={5}>
                  <Form.Label>Village/City गाव/शहर(मराठी) :</Form.Label>
                  <Form.Control
                    sm={12}
                    md={4}
                    value={CityMr}
                    onChange={(e) => setCityMr(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={7}>
                  <Form.Label>
                    घर नं/कॉलनी/रोड (मराठी) :</Form.Label>
                  <Form.Control
                    sm={12}
                    md={8}
                    className="place"
                    placeholder="घराचे नाव/नं/रोड/चौक/कॉलनी/लँडमार्क"
                    value={AddressMr}
                    onChange={(e) => setAddressMr(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
              </Row> */}

              <Row style={{ paddingTop: "30px" }}>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Label>Email Id(ई– मेल) :</Form.Label>
                  <Form.Control
                    type="email"
                    value={Email1}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Label>Mobile No 1 (मोबाईल नं. 1) :</Form.Label>
                  <Form.Control
                    type="number"
                    value={Mobno}
                    onChange={(e) => setMobno1(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Label>Mobile No 2 (मोबाईल नं. 2):</Form.Label>
                  <Form.Control
                    type="tel"
                    value={Mobno2}
                    pattern="[0-9]{10}"
                    maxlength="10"
                    title="Please enter 10 digit no."
                    onChange={(e) => setMobno2(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
              </Row>
              {/* </Form> */}
            </div>
          </div>

          <div className="px-md-5 pt-md-4 pb-md-4 pb-sm-4 pb-4">
            <div
              className=" rounded shadow p-3 pb-4"
              style={{ backgroundColor: "white" }}
            >
              {/* <Form onSubmit={handleSave4}> */}
              <Row style={{ paddingTop: "10px" }}>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "10px",
                      borderBottom: "1px solid gray",
                      color: "rgb(134, 28, 18)",
                    }}
                  >
                    <h5>
                      <b>Family Information (कौटुंबिक माहिती)</b>
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} className="pt-2 pt-md-0">
                  <Form.Label>
                    Father's Name English (वडिलांचे नाव इंग्रजी) :
                  </Form.Label>
                </Col>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Control
                    className="mt-2"
                    placeholder="First Name En"
                    value={FaFNameEn1}
                    onChange={(e) =>setFaFNameEn(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Control
                    className="mt-2"
                    placeholder="Middle Name En"
                    value={FaMNameEn}
                    onChange={(e) => setFaMNameEn(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4} className="pt-2 pt-md-0">
                  <Form.Control
                    className="mt-2"
                    placeholder="Last Name En"
                    value={FaLNameEn1}
                    onChange={(e) => setFaLNameEn(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
              </Row>

              {/* <Row style={{ paddingTop: "20px" }}>
                <Col sm={12}>
                  <Form.Label>
                    Father's Name In Marathi (वडिलांचे नाव मराठी) :
                  </Form.Label>
                </Col>
                <Col sm={12} md={4}>
                  <Form.Control
                    className="mt-2"
                    placeholder="पहिले नाव"
                    value={FaFNameMr}
                    onChange={(e) => setFaFNameMr(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Form.Control
                    className="mt-2"
                    placeholder=" मधले नाव"
                    value={FaMNameMr}
                    onChange={(e) => setFaMNameMr(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Form.Control
                    className="mt-2"
                    placeholder="अडनाव"
                    value={FaLNameMr}
                    onChange={(e) => setFaLNameMr(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  />
                </Col>
              </Row> */}
              {gender1 == 1 && (
              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} md={3} className="pt-2 pt-md-0">
                  <Form.Label>Farm In Acar /शेत जमीन एकर :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={FarmAcar}
                    onChange={(e) => setFarmAcar(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose... </option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                    <option>25</option>
                    <option>26</option>
                    <option>27</option>
                    <option>28</option>
                    <option>29</option>
                    <option>30</option>
                    <option>31</option>
                    <option>32</option>
                    <option>33</option>
                    <option>34</option>
                    <option>35</option>
                    <option>36</option>
                    <option>37</option>
                    <option>38</option>
                    <option>39</option>
                    <option>40</option>
                    <option>41</option>
                    <option>42</option>
                    <option>43</option>
                    <option>44</option>
                    <option>45</option>
                    <option>46</option>
                    <option>47</option>
                    <option>48</option>
                    <option>49</option>
                    <option>50</option>
                    <option>Above 50...</option>
                  </Form.Select>
                </Col>
                <Col sm={12} md={3} className="pt-2 pt-md-0">
                  <Form.Label>Farm In Guntha /शेत जमीन गुंठे :</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={FarmGuntha}
                    onChange={(e) => setFarmGuntha(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose... </option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                    <option>25</option>
                    <option>26</option>
                    <option>27</option>
                    <option>28</option>
                    <option>29</option>
                    <option>30</option>
                    <option>31</option>
                    <option>32</option>
                    <option>33</option>
                    <option>34</option>
                    <option>35</option>
                    <option>36</option>
                    <option>37</option>
                    <option>38</option>
                    <option>39</option>
                  </Form.Select>
                </Col>
                <Col sm={12} md={6} className="pt-2 pt-md-0">
                  <Form.Label>Other Property (इतर मालमत्ता ):</Form.Label>
                  <Form.Control
                    value={OtherProperty}
                    onChange={(e) => setOtherProperty(e.target.value)}
                    disabled={isEditing ? false : true}
                  />
                </Col>
              </Row>
               )}


              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} md={2} className="pt-3">
                  <Form.Label>Brother / भाऊ :</Form.Label>
                </Col>
                <Col sm={6} md={2} className="pt-3">
                  <Form.Label>Married Brothers (In Num.):</Form.Label>
                </Col>
                <Col sm={6} md={2}>
                  {/* <Form.Control
                    className="mt-2"
                    placeholder="Married Brother"
                    type="number"
                    value={MarriedBro}
                    onChange={(e) => {
                      // Limit the input to one digit
                      if (e.target.value.length <= 1) {
                        setMarriedBro(e.target.value);
                      }
                    }}
                    required
                    disabled={isEditing ? false : true}
                    maxLength="1"
                  /> */}
                  <Form.Select
                    aria-label="Default select example"
                    value={MarriedBro}
                    onChange={(e) => setMarriedBro(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                  </Form.Select>
                </Col>
                <Col sm={6} md={2} className="pt-3">
                  <Form.Label>Unmarried Brother (In Num.):</Form.Label>
                </Col>
                <Col sm={6} md={2}>
                 {/* <Form.Control
                    className="mt-2"
                    placeholder="UnMarried Brother"
                    type="number"
                    value={UnMarriedBro}
                    onChange={(e) => {
                      // Limit the input to one digit
                      if (e.target.value.length <= 1) {
                        setUnMarriedBro(e.target.value);
                      }
                    }}
                    required
                    disabled={isEditing ? false : true}
                    maxLength="1"
                  /> */}
                  <Form.Select
                    aria-label="Default select example"
                    value={UnMarriedBro}
                    onChange={(e) => setUnMarriedBro(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col sm={12} md={2} className="pt-2">
                  <Form.Label>Sister / बहीण :</Form.Label>
                </Col>
                <Col sm={6} md={2} className="pt-2">
                  <Form.Label>Married Sister (In Num.) :</Form.Label>
                </Col>
                <Col sm={6} md={2}>
                  {/* <Form.Control
                    placeholder="Married Sister"
                    type="number"
                    value={MarriedSis}
                    onChange={(e) => {
                      // Limit the input to one digit
                      if (e.target.value.length <= 1) {
                        setMarriedSis(e.target.value);
                      }
                    }}
                    required
                    disabled={isEditing ? false : true}
                    maxLength="1"
                  /> */}
                  <Form.Select
                    aria-label="Default select example"
                    value={MarriedSis}
                    onChange={(e) => setMarriedSis(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                  </Form.Select>
                </Col>
                <Col sm={6} md={2} className="pt-3">
                  <Form.Label>Unmarried Sister (In Num.) :</Form.Label>
                </Col>
                <Col sm={6} md={2}>
                  {/* <Form.Control
                    placeholder="UnMarried Sister"
                    type="number"
                    value={UnMarriedSis}
                    onChange={(e) => {
                      // Limit the input to one digit
                      if (e.target.value.length <= 1) {
                        setUnMarriedSis(e.target.value);
                      }
                    }}
                    required
                    disabled={isEditing ? false : true}
                    maxLength="1"
                  /> */}
                  <Form.Select
                    aria-label="Default select example"
                    value={UnMarriedSis}
                    onChange={(e) => setUnMarriedSis(e.target.value)}
                    required
                    disabled={isEditing ? false : true}
                  >
                    <option value="">Choose...</option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                  </Form.Select>
                </Col>
              </Row>
              {/* </Form> */}
            </div>
          </div>
          <div
            className="pe-5 "
            style={{ display: "flex", justifyContent: "end" }}
          >
          {isLoading ? (
            <Button 
              style={{
                display: "flex",
                backgroundColor: "grey",
                border: "none",
                justifyContent: "center",
              }}
              className="my-4 py-2 px-4 mt-5"
              disabled
            >
              Saving...
            </Button>
              
            ) : update === false ? (
              <button
                type="submit"
                className="rounded"
                style={{
                  padding: "8px 22px",
                  backgroundColor: "rgb(233, 224, 227)",
                  border: "0px",
                  color: "rgb(134, 28, 18)",
                  marginBottom: "30px",
                  fontSize: "25px",
                }}
              >
                {" "}
                <b>Save</b>
              </button>
            ) : null}
          </div>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
        {isLoading ? (
          <Button 
              style={{
                display: "flex",
                backgroundColor: "grey",
                border: "none",
                justifyContent: "center",
              }}
              className="my-4 py-2 px-4 mt-5"
              disabled
            >
              Updating...
            </Button>
            ) : update === true ? (
            <button
              onClick={updatedata}
              className="rounded"
              style={{
                padding: "7px 15px",
                backgroundColor: "rgb(233, 224, 227)",
                border: "0px",
                color: "rgb(134, 28, 18)",
                marginBottom: "30px",
                fontSize: "20px",
              }}
            >
              {" "}
              <b>Update</b>
            </button>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default FirstPage;
