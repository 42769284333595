import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { adminPaymentSuccessList } from '../../AllApi/AdminApi/AdminApi';
import { MDBDataTable } from 'mdbreact';
import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { BarCodeRecipt, PaymentSuccessApi, RegistrationSelect } from '../../AllApi/UserApi/UserApi';
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import Logo from '../../assets/Mainlogo.jpg';
import Logo2 from '../../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import blink from '../../assets/photo_rejected.gif'

const PaymentSuccessMembers = () => {
//========================================================================================================

// const userdata = JSON.parse(sessionStorage.getItem("userdata"));
// const member_id = userdata.id


const navigate = useNavigate();
  const [record, setRecord] = useState([]);
  const [showLargePhoto, setShowLargePhoto] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

   //----------------------------------API code------------------------------------------

   useEffect(() => {
    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

    const fetchMemberData = async () => {
      try {
        const response = await axios.get(
          adminPaymentSuccessList,
          tokenConfig);
        setRecord(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  fetchMemberData()
}, [])


  //--------------------------Image click code-----------------------------------

  const handleImageClick = (item) => {
  
    // navigate('/admin/paymentReceipt', { state: item.member_id });
    navigate('/admin/paymentReceipt', { state: { member_id: item.member_id, reg_id: item.reg_id } });

  }

  const handleCloseLargePhoto = () => { 
    // setShowLargePhoto(false);
  };


   //CSS for Information-------------------------------------------------------------------
  
  const footerStyle = {
    backgroundColor:"#660505",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#fff",
    fontSize:"12px",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
  }
   //=====================================DATA TABLE============================================


const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Member Id',
    field: 'member_id',
    sort: 'asc',
  },
  {
    label: 'Reg.Id',
    field: 'reg_id',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'name_en',
    sort: 'asc',
  },
  {
    label: 'Gender',
    field: 'gender',
    sort: 'asc',
  },
  {
    label: 'Marital Status',
    field: 'marital_status',
    sort: 'asc',
  },
  {
    label: 'Disability',
    field: 'disability',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'emailid',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mobileno',
    sort: 'asc',
  },
  {
    label: 'View Receipt',
    field: 'action',
  },
];


const customRows = record.map((item, index) => {
  const { gender, name_en,marital_status,disability, mobileno, emailid,member_id,reg_id } = item;
  const genderLabel = gender === 1 ? 'Male' : 'Female';
  const disabled=disability === "1" ? 'No' : 'Yes';
  return {
    srNo: index + 1,
    member_id,
    reg_id,
    name_en,
    gender:genderLabel,
    marital_status,
    disability:disabled,
    mobileno,
    emailid,
    action: (
      <button className="viewButtons1" type='submit' onClick={() => handleImageClick(item)}>View Receipt</button>
      
    ),
  };
});

//==================================================================================


  return (
   
    <>
    <div className="row m-0">
      <div className='p-0'> <AdminMasterHeader/> </div>
      <div className="filter-buttons row">
        <div className='col-12 ps-0'>
         <h2 style={{ fontWeight: '550',textAlign:"start"}}>Payment Success Members</h2>
        </div>
      </div>


     <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
        <div className="col-12 col-sm-1"></div>
        <div className="col-12 col-sm-10" style={{overflowX:"auto"}}>
        
            <MDBDataTable
              className='mb-5 custom-datatable'
              striped
              bordered
              hover
              small
              paging={!showLargePhoto}
              data={{ columns, rows: customRows }}
              style={{ textAlign: "center", fontSize: "14px",}}/>
        </div>
        <div className="col-12 col-sm-1"></div>
    </Row>
     
     

    </div>
    <div style={footerStyle} className='mt-5 fixed-bottom'>
        &copy; 2024-25 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
    </>
  )
}

export default PaymentSuccessMembers