import React,{useEffect,useState} from 'react'
import axios from 'axios'
import './Login1.css'
import {useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from './assets/Mainlogo.jpg';
import Logo2 from './assets/logo2.png';
import beforelogo from './assets/login-before.png'
import Registartion1 from './UserComponents/LoginNavComponents/Registration1';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { BaseIP, Forgetuser, RegistrationStop, UserLogin } from './AllApi/UserApi/UserApi';
import { Form, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';



function Login1() {

    
  useEffect(() =>{
    window.scrollTo(0, 0);
  },[])
  
  const navigate=useNavigate()

    //---------------------------------Disabled back button-------------------------------
    useEffect(() => {
      window.history.pushState(null, null, window.location.href)
  
      const handleBackButton = (event) => {
        event.preventDefault()
        window.history.forward()
      }
  
      window.addEventListener('popstate', handleBackButton)
  
      return () => {
        window.removeEventListener('popstate', handleBackButton)
      }
    }, [])
   
        //Modal 
        const [show, setShow] = useState(false);
        const [show1, setShow1] = useState(false);
        const handleClose = () => setShow(false);
        const handleClose1 = () => setShow1(false);


const [lockDateOk,setlockDateOk]=useState(false)    
const [email,setEmail] =useState('');
const [password,setPassword] =useState('')
const [data, setData] = useState()
const [isLoading, setIsLoading] = useState(false);



const saveFun=(e)=>{
    e.preventDefault();

    if(lockDateOk===true){
      alert("Registration For Supplement Book Will Be Started On 24-Nov-2024 , पुरवणी पुस्तिका नोंदणी 24 नोव्हेंबर 24 ला सुरु होईल")
            }
            else{


    if(!email){
        // alert("Email Id must not be empty.")
        toast.error('Email Id must not be empty', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return false;
      }
      else if(!password){
        toast.error('Password must not be empty', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      
    

    const saveObj ={
        email:email,
        password:password          
    }  
    setIsLoading(true); 

    axios.post(UserLogin,saveObj)
    .then((res)=>{
      setIsLoading(false);
        if(res.data ==="NOTFOUND"){
            toast.error('Invalid login details', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
        }
        else if(res.data !==null){
            sessionStorage.setItem("token", JSON.stringify(res.data.token));
            sessionStorage.setItem("userdata", JSON.stringify(res.data.userdata));
            sessionStorage.setItem("status", JSON.stringify(res.data.status));
            //  setIsUser(true)
            if(res.data.status === 3){
              navigate('/userDashboard') // member register reg_id
            }
            else if(res.data.status === 4){
              navigate('/user/regForm') //exp_housewife
            }
             else if(res.data.status === 5){
              navigate('/user/regForm') //photo upload
             }
            else if(res.data.status === 6){
              navigate('/payment') //payment pending
            }
            // else if(res.data.status === 7){
            //   navigate('/mainpage')
            // }
            else if(res.data.status === 7){
              //wait for admin payment approval
              toast.warn('Wait for admin payment approval', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }); 
            }
             else if(res.data.status === 8){
              navigate('/payment') //admin payment reject
            }
             else if(res.data.status === 9){
              navigate('/mainpage') //admin payment success
            }
            else{
              navigate('/')
            }
        }
    })
    .catch((err)=>{
      setIsLoading(false);
        setIsLoading(false);
        toast.error('Server Error', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    })
    
}}

    // useEffect(() => {
    //   checkLogin()
    // }, [])

    const regFun=()=>{
      if(lockDateOk===true){
alert("Registration For Supplement Book Will Be Started On 24-Nov-2024 , पुरवणी पुस्तिका नोंदणी 24 नोव्हेंबर 24 ला सुरु होईल")
      }
      else{

      navigate('/registration1')
      // <Registartion1 />
          }    }

    const forgotFun =()=>{
      navigate('/user/forgotpassword')
    }


    // const navigate = useNavigate();
    // const isLoggedIn = !!sessionStorage.getItem("userdata");
    // useEffect(() => {
    //   if (!isLoggedIn) {
    //     navigate("/");
    //   }
    // }, [isLoggedIn, navigate]);

    useEffect(() => {
      let sessionData1=sessionStorage.getItem("userdata")
      let sessionData2=sessionStorage.getItem("token");

      if(sessionData1 !==null && sessionData2 !==null){
        navigate("/userDashboard")
      }
    }, [])

    //--------------- forgotpassword---------------------------//

      const [forgetshow,setForgetshow]=useState()
      const [forgetemail,setForgetemail]=useState()
      const [dateofbirth,setdateofbirth]=useState()
      const handleforgetshow = () => {
        setForgetshow(true)
        // setLogshow(false)
      };
      const handleforgetClose = () => setForgetshow(false);
      const handleEmailforChange = (e) => {
        setForgetemail(e.target.value);
       
      };
      const handledobforChange = (e) => {
        
        setdateofbirth(e.target.value);
      };

      const handleforgetlog=(event)=>{
        event.preventDefault();
        const forgotdata={
          email:forgetemail,
          dateofbirth:dateofbirth
        }
         
        axios.put(Forgetuser,forgotdata)
        .then((res) => {
            if (res.data.status === "SUCCESS") {
              // alert("Your Password Has Been Sent On Email");
              toast.success('Your Password Has Been Sent On Email', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setForgetshow(false)
            } 
            else if(res.data==="ERROR") {
              // alert("Cheack Your Enter Emailid");
              toast.error('Check Your Entered Email Id', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setForgetshow(false)
            }
            // else if(res.data==="FAILED"){
            //   alert("Failed To Send Email");
            //   setForgetshow(false)
              
            // }
            else {
              toast.error('Server Error', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              
            }
          })
          .catch((err) => {
              // alert("Failed To Send Email, try again");
              toast.error('Failed To Send Email, try again', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setForgetshow(false)
            });

}

          // const Getdata = (e) => {
          //   axios
          //     .get(RegistrationStop)
          //     .then((res) => {
          //       if (res.data !== null) {
          //         console.log("++++",res.data);
          //         const locadat=res.data
          //         const currentDate = new Date();
          //       const formattedDate = currentDate.toISOString().split('T')[0];
          //       console.log(formattedDate);
          //         if(formattedDate>locadat){
          //           setlockDateOk(true)
          //         }

          //       }
          //     })
          //     .catch((err) => {
          //       toast.error("Failed to upload, try again", {
          //         position: "top-right",
          //         autoClose: 3000,
          //         hideProgressBar: false,
          //         closeOnClick: true,
          //         pauseOnHover: true,
          //         draggable: true,
          //       });
          //       console.log(err);
          //     });
          // };

          // useEffect(() => {
          //   Getdata();
          // }, []);

    

  return (<>
    <Container fluid className='p-0 '>
      <Row sm={12} className='header m-0 p-0 py-1' style={{borderBottom:" 2px solid red",display:"flex",justifyContent:"space-between"}}>
        <Col sm={3} md={3}  style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col sm={6} md={6}  style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center pt-1'>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col>    

        <Col sm={3} md={3}  style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col>    
      </Row>   
       
      <Row className='popup m-0 p-0'>
        {/* <Col  sm={12} style={{display:"flex",justifyContent:"center"}}> */} 
            {/* <div className='text-center pt-2'> */}
              <marquee scrollamount="11" style={{display:"flex",backgroundColor:"rgb(233, 224, 227)",}}>
                <h4 className='fw-bolder pt-2'>19th Jain Vadhu-Var Palak Melava 2024 &nbsp; &nbsp; &nbsp; Sunday, 8th Dec. 2024  &nbsp; &nbsp; &nbsp; Registration For Melava Book Will Be Started On 15-Sept-2024 , मेळावा पुस्तिका नोंदणी 15 सप्टेंबर 24 ला सुरु होईल. &nbsp; &nbsp; Venue:  Aacharya Adisagar Sanskrutik Bhavan, Kolhapur Road, Ankali, Sangli.</h4>
              </marquee>  
           {/* </div> */}
        {/* </Col> */}
      </Row>



    <Row className='wrap pb-5 m-0'>
        <Col md={6} sm={12}></Col>
        <Col md={6} sm={12} style={{display:"flex",justifyContent:"center",paddingTop:"15px" }}>
          <Nav>  
            {/* <Nav.Link href="/registration1" className='text-white mx-2'>Registration</Nav.Link> */}
            <Nav.Link  className='text-white mx-2' onClick={() => setShow(true)}>Terms & Condition</Nav.Link>
            <Nav.Link  className='text-white mx-2' onClick={() => setShow1(true)}>Privacy Policy</Nav.Link>
          </Nav>  
        </Col>

        <Col md={6}></Col>       
        <Col md={6}>
          <div className='login-container'>     
              <div className='login-form'>

              <div className='register-button pb-2'>
                    <button className='register-btn gif-button' onClick={regFun}  style={{textDecoration:"none",color:"white",fontSize:"25px"}}> <span style={{fontSize:"15px"}}>New User</span> <br /> Register Here</button>
                </div> 

                <form onSubmit={saveFun}>
                    <div className='input py-2 px-3' >

                        <div style={{display:"flex",justifyContent:"center"}}>
                          <img  src={beforelogo}/>
                        </div>
                        
                        <h3 className='text-center'>Registered User Login</h3>
                        <input id='input' value={email} type="email" placeholder='Email Id' onChange={(e)=>setEmail(e.target.value)} required/>

                        <input id='input1' value={password} type="password" placeholder='Password' onChange={(e)=> setPassword(e.target.value)}  required/>

                        <div className='login-button'>
                        <button type="submit"  className='login-btn' disabled={isLoading} > {isLoading ? 'Loading...' : 'LOGIN'}</button>
                        </div>
                    </div>

                    <div  className='pt-3' style={{display:"flex",justifyContent:"end"}}>
                        <Nav.Link   style={{color:"white"}} onClick={() => setForgetshow(true)}><small className='forgot'>Forgot Password?</small></Nav.Link>
                    </div>

                  
                </form> 
                  
              </div>
           </div>
       </Col>
    </Row>
    
    <Row className='timetable py-3 m-0'>
      <Col>
        <Row className='m-0 py-1'>
          <Col sm={12} md={6} className='pt-2 pt-md-0'  style={{display:"flex",justifyContent:"start",}}>
            <div className='py-2 px-3 rounded text-light' style={{backgroundColor:"rgb(112, 22, 12)"}}>Melava Registration Start Date : 15 September 2024</div>
          </Col>
          <Col sm={12} md={6} className='pt-2 pt-md-0' style={{display:"flex",justifyContent:"end",}}>
           <div className='py-2 px-3 rounded text-light' style={{backgroundColor:"rgb(112, 22, 12)"}}>Melava Registration End Date : 20 November 2024</div>
          </Col>
        </Row>
        <Row className='m-0 pb-3'>
          <Col sm={12} md={12}  className='pt-2 pt-md-0' style={{display:"flex",justifyContent:"center"}}>
          <div className='py-2 px-3 rounded  register-btn gif-button' style={{backgroundColor:"rgb(112, 22, 12)",textDecoration:"none",color:"white"}}>Melava Date : 8 December 2024</div>
          </Col>
        </Row>
        <Row className='m-0 py-1'>
          <Col sm={12} md={6} className='pt-2 pt-md-0' style={{display:"flex",justifyContent:"start"}}>
          <div className='py-2 px-3 rounded text-light' style={{backgroundColor:"rgb(112, 22, 12)"}}>Supplement Book Registration Start Date : 23 November 2024</div>
          </Col>
          <Col sm={12} md={6} className='pt-2 pt-md-0' style={{display:"flex",justifyContent:"end"}}>
          <div className='py-2 px-3 rounded text-light' style={{backgroundColor:"rgb(112, 22, 12)"}}>Supplement Book Registration End Date : 22 December 2024</div>
          </Col>
        </Row>
      </Col>
    </Row>

      {/* -----------------footer login --------------------------- */}
    <div className='m-0'>
    <Container fluid className='footer1-container p-md-2 m-0 px-md-5' style={{boxSizing:"border-box"}}>
      <Row className='py-1' >
        <Col sm={12} md={5} className='text-white text-center  py-3'>
        <div >
          <img src={Logo}  alt="" width={100} height={100}/>
        </div> 
        <div className='text-center' style={{color: "rgb(134, 28, 18)"}}>
            <h6 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h6>
            <h5 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h5>
            <h6 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h6>
        </div>
        </Col>
        <Col sm={12} md={3} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color:"rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Information</b></h4>
            </div>
            <div className='text-start ps-5'>
            <Link to="/PrivacyPolicy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)" }} >
                   <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>   
                    Privacy Policy 
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            <Link to="/ReturnAndRefundPrivacy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Return & Refund Policy
            </Link><br/>
            <Link to="/Cancelation"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Cancelation Policy
            </Link><br/>
            {/* <Link to="/Registration1"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Registration
            </Link><br/> */}
            <Link to="/loginBarCode"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login(Entry Pass)
            </Link><br/>
            <Link to="/admin"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login
            </Link><br/>
            </div>
        </Col>
        <Col sm={12} md={4} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color: "rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Contact</b></h4>
            </div>
            <div className='text-start ps-5'>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-address-book"></i> <span>Dakshin Bharat Jain Sabha's Sheth R D Dawada Digambar Jain Boarding, Sangli</span>
              </a>
              <br/>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-location-dot"></i>
              <b>Address</b> : <span>37, Mahavirnagar, Opp Sangli Highschool, Sangli-416416</span>
              </a>
              <br/>
            <a href="mailto:jainboardingsangli@gmail.com" style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-envelope"></i>   
              <b>Email</b> : <span>jainboardingsangli@gmail.com</span>
              </a>
              <br/>
              <a href="tel:02332623206" style={{ textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-phone"></i> 
             <b>Contact</b> : 0233 2623206
            </a><br/>
            </div>
        </Col>
      </Row>
      <Row>
        <Col>
        <div style={{display:"flex", justifyContent:"center", color:"white", padding:"20px 0px 20px 0px", borderTop:"1px solid rgb(134, 28, 18) ",color: "rgb(134, 28, 18)"}}>
            &copy; 2024-25 Jain Boarding | Managed By : TechnoAarv Solution
        </div>
        </Col>
      </Row>
    </Container>
    </div>
    </Container>

                {/*//Forget PassWord Modal */}

     <Modal show={forgetshow} onHide={handleforgetClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Fill Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form  onSubmit={handleforgetlog}>
            <Row className="mb-3">
              <Form.Group md="4" controlId="validationCustom01">
                <Form.Label>Enter Email</Form.Label>
                <Form.Control
                  required
                  type="Email"
                  placeholder="Enter Email"
                  onChange={handleEmailforChange}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Email Please
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group md="4" controlId="validationCustom01">
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control
                  required
                  type="date"
                  placeholder="Enter Email"
                  onChange={handledobforChange}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Email Please
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button type="submit">Send Now</Button>
          </Form>
        </Modal.Body>
      </Modal>


       
        {/*   --------------Terms & Condition Modal-------------- */}
    <Modal
    show={show}
    onHide={handleClose}
    dialogClassName="modal-100w"
    size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
  >
    <Modal.Header  closeButton style={{backgroundColor: "rgb(233, 224, 227)"}}>
      <Modal.Title id="example-custom-modal-styling-title" style={{color: "rgb(134, 28, 18)"}}>
        Terms & Condition
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
      These terms and conditions (“Terms and Conditions”) control your use of this website: registration. jainboardingsangli.com (Website).
      </p>
      <p>
      You refers to a user or a paying customer. If You are a company or another person who gives access to Institute services, you agree to take responsibility in full in case of damages or indemnification that could properly lie against the customer.
      </p>
      <p>
      By using or accessing the information on this website, including, but not limited to downloading or accessing services through this Website or through designated Software of the Institute; You agree to abide by the terms and conditions set forth in these “Terms and Conditions”:
      </p>
      <h5>GENERAL TERMS OF USE</h5>
      <p>
      Registering at this Website does not entitle You to the right of admission/selection for any course. The Institute Selection Committee reserves the right to select or reject a candidate without any notice or assigning any reason whatsoever.
      </p>
      <p>
      Selected candidates are required to comply with the rules laid down under the various Policies and Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli), reserves the right to expel a student or terminate the candidature at any time if found otherwise.
      </p>
      <p>
      As a user, You agree to maintain (i) all content in the account including but not limited to academic content, assessments, email/SMS content, profile information and current billing and contact information, (ii) Email/mobile phone number list and Address Book maintenance including opt-out contact information and immediate removal of opt-outs and bounces from email/mobile phone number lists and the address book, (iii) Payment of all agreed course fees (iv) full responsibility for all activity associated with your use of this Website and services and (v) compliance with all other obligations set forth in these Terms and Conditions.
      </p>
      <p>
      You will provide current, complete, accurate information in the registration section of this Website. You will further update and keep that information current as needed. Email login must be a valid email address maintained by You. You are solely responsible for maintaining the confidentiality of your password and account information. You will immediately notify the Institute of any unauthorized account activity, or any unauthorized use of your email list(s) or any other breach of security You become aware of by emailing at: jainboardingsangli@gmail.com
      </p>
      <p>
      Excluding third party course materials,Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) owns all copyrights, patents, trade secrets, trademarks and other intellectual property rights, title and interest in or pertaining to the services in the course catalogue and all other services developed by Institute for the purpose of these services on offer. No resale, transfer or re-export of the course(s) and/or content is authorized without the prior authorization of Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli).
      </p>
      <p>
      Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) reserves the right to change course prices at any time. In the event of such change, confirmed registrations will be honored at the original price. Changes to Service Tax and other tax and duty components will be incorporated into the Course Fee as and when such changes occur and the student will become liable to pay the incremental fee.
      </p>
      <p>
      Course content for all the services on offer is provided for the purpose of education and guidance only. The course content is regularly reviewed and is subjected to change without notice. Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) reserves the right to modify training content without notice.
      </p>
      <p>
      Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) may terminate your access at any time for any reason. The provisions regarding a disclaimer of warranty, accuracy of information and indemnification shall survive such termination. Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) may also monitor access to this Website.
      </p>
      <p>
      To the extent allowed by local law, in no event will Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) or its affiliates, subcontractors or suppliers be liable for any incidental, indirect, special consequential damages.
      </p>
      <p>
      By registering on this Website, You give your consent to receive, from time to time, text messages from Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) which may include offers from Us or our affiliates and partners.
      </p>
      <h5>ONLINE PAYMENT TERMS</h5>
      <p>
      The following section states the Terms and Conditions which will be applicable to the user using the Institute's on-line payment process for various services/services offered by Us. Please read this page carefully. If these Terms & Conditions are not acceptable to You, please do not use this process. By using Institute's online payment process, You are indicating your acceptance to be bound by these Terms & Conditions. Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) reserves the right to revise these Terms & Conditions from time to time.
      </p>
      <p>
      User information entered during the online payment process is kept secure and confidential and will not be divulged to any third party, other than the payment gateway service provider.
      </p>
      <p>
      The Institute will not be held responsible for any damages the user may suffer as a result of using the payment process. The user agrees and shall defend, indemnify, save and hold the Institute harmless from any and all demands, liabilities, losses, costs and claims that may arise or result from incorrect use of the online payment process or any other causes attributable to the user.
      </p>
      <p>
      The Institute will not be responsible for any refunds arising out of any failures in online payments in case the amount gets deducted from user’s saving/current account and does not get credited in the Institute's account, before the transaction is complete. Users in this case will have to contact either the Bank or Us to clarify the same.
      </p>
      <p>
         In no event will the user be eligible for the refund of the payment made online.
      </p>
      <p>
      All the refunds of credit & debit card transactions, which are subject to the Institute's policy, will be made by cheques only; no refund will be processed either by online transfer or in cash.
      </p>
      <p>
      Receipts of online payments along with confirmation of admissions by Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) should be maintained by the user safely, as this will be a pre-condition for processing any refund.
      </p>
      <p>
      Successful completion of the online application process and payment alone will not make the user eligible for the admission. Since the online application system does not perform any check on the qualification of user or other essential conditions during the application process, hence, successful completion of the online application process may not make the user eligible for the course admission. The course admission will be totally at the discretion of Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) and the same will be confirmed upon scrutiny of the admission details provided by the user and Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) is not liable to give reason for any rejection.
      </p>
      <p>
      It is the user’s responsibility to ensure payment of full fees as applicable. Such payment of fees by or on behalf of the user does not in itself constitute acceptance of user’s candidature for the course. When the user successfully completes the online payment transaction using credit/debit cards option, funds will be deducted from the credit/debit card mentioned by the user and the payments will be credited to the Institute . Any payment received through this website will be treated as having been made by the user, even though the same may have been made by some other person/party, in such event, the Institute shall not be liable to entertain any correspondence from any person other than the user’s parents/guardian.
      </p>
      <p>
      In case the user registers successfully but fails to pay the required fee on or before the stipulated date, such application will stand cancelled without any further notification.
      </p>
      <p>
      The Institute shall not be liable to protect data from third parties if there is any failure on the part of the user or any person acting on behalf of the user, during the online payment process or in respect of any omission to provide accurate information in the course of the online payment process.
      </p>
      <p>
      The Institute will be entitled to use the information given during the online payment process for the purpose of registration of the user and also for statistical purposes or for its internal use.
      </p>
    </Modal.Body>
    <Modal.Footer style={{backgroundColor: "rgb(233, 224, 227)"}}>
          <Button  onClick={handleClose} style={{backgroundColor: "rgb(134, 28, 18)", border:"1px solid rgb(134, 28, 18)"}}>
            Close
          </Button>
        </Modal.Footer>
  </Modal>

          {/* PrivacyPolicy Modal */}

          <Modal
    show={show1}
    onHide={handleClose1}
    dialogClassName="modal-100w"
    size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
  >
    <Modal.Header closeButton style={{backgroundColor: "rgb(233, 224, 227)"}}>
      <Modal.Title id="example-custom-modal-styling-title" style={{color: "rgb(134, 28, 18)"}}>
        Privacy Policy
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
      We respect the privacy of those who visit our website. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our users.
      </p>
      <h5>What this Privacy Policy Covers?</h5>
      <p>
      This Privacy Policy covers our treatment of personally identifiable information that we collect when you are on our site, and when you use our services. This policy also covers our treatment of any personally identifiable information that third parties share with us.</p>
      <p>
      This policy does not apply to the practices of organizations that we do not own or control, or to people that we do not employ or manage.
      </p>
      <h5>Information Collection and Use</h5>
      <p>
      We collect personally identifiable information when you register on our website, when you use our services, and when you visit our pages. We may also receive personally identifiable information from third parties.
      </p>
      <p>
      When you register with us, we ask for your name, email address, zip code, occupation, industry, and personal interests. Once you register with us and sign in to our services, you are not anonymous to us.
      </p>
      <p>
      The e-mail or contact number address You submit to receive information from bduedu.in will never be disclosed to any third party. All e-mail that You receive will be from the domain names bduedu.in.
      </p>
      <p>
      Bharathidasan University has adopted and adheres to stringent security standards designed to protect non-public personal information at this Website against accidental or unauthorized access or disclosure. Among the safeguards that Bharathidasan University has developed for this Website are administrative, physical and technical barriers that together form a protective firewall around the information stored at this site.
      </p>
      <p>
      We use this information for three general purposes: to customize the content you see, to fulfill your requests for certain services, and to contact you about services.
      </p>
      <p>
      We also automatically receive and record information on our server logs from your browser including your IP address, cookie information and the page you requested. This information is not linked to your account and will not be used to identify you.
      </p>
      <h5>Information Sharing and Disclosure</h5>
      <p>We will not sell or rent your personally identifiable information to anyone. We will send personally identifiable information about you to other companies or people when:</p>
      <p>
      We have your consent to share the information. <br/>
      We respond to subpoenas, court orders or legal process,;<br/>
      or We find that your actions on our web sites violate the Terms of Service
      </p>
      <h5>Changes to this Privacy Policy</h5>
      <p>
      The privacy policy is subject to modification from time to time. If we decide to change our privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information, and whether we will disclose it to anyone. Any significant changes to our privacy policy will be announced on our home page. If you do not agree with the changes in our policy you can simply discontinue to visit our website.
      </p>
      <h5>Questions or Suggestions</h5>
      <p>If you have questions or suggestions send an email to us:</p>
      <p><a >jainboardingsangli@gmail.com</a></p>
      
    </Modal.Body>
    <Modal.Footer style={{backgroundColor: "rgb(233, 224, 227)"}}>
          <Button  onClick={handleClose1} style={{backgroundColor: "rgb(134, 28, 18)", border:"1px solid rgb(134, 28, 18)"}}>
            Close
          </Button>
        </Modal.Footer>
  </Modal>       

    </> 
  )
}

export default Login1
